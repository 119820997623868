// erste Spalte

.meinElement1, .meinElement2 {
  opacity: 0;
  transition: all 2.25s ease-in-out;
}

.meinElement1 {
  transform: translateX(-90%);
}

.meinElement2 {
  transform: translateX(90%);
}

.meinElement1.visible {
  transform: translateX(0);
  opacity: 1;
}

.meinElement2.visible {
  transform: translateX(0);
  opacity: 1;
}

// zweite Spalte

.meinElement3, .meinElement4 {
  opacity: 0;
  transition: all 2.25s ease-in-out;
}

.meinElement3 {
  transform: translateX(-90%);
}

.meinElement4 {
  transform: translateX(90%);
}

.meinElement3.visible {
  transform: translateX(0);
  opacity: 1;
}

.meinElement4.visible {
  transform: translateX(0);
  opacity: 1;
}


/*
@keyframes wipe-enter {
  0% {
    transform: scale(0, 0.025);
  }
  50% {
    transform: scale(1, 0.025);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .square {
    animation-name: wipe-enter;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

.square {
  width: 200px;
  height: 200px;
  background: orange;
  // etc...
}

@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    animation: wipe-enter 1s 1;
  }
}
*/