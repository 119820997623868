// Globale Variable

/*
 blau-dunkel: #0059A7 
 blau-mittel: #AEBDDD
*/

// Change bootstrap defaults
$primary: hsl(358, 66%, 44%);
$text-color: #000000;
