body {
    font-family:'Koblenz','Verdana', Arial, Helvetica, sans-serif;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    font-family:'Koblenz','Verdana', Arial, Helvetica, sans-serif;
}

.container-fluid {
    padding: 0 !important;
}

.content-wrap {
    padding: 0;
}

#content {
    background-color: #F8F9FA !important;
}

#footer {
    border-top: none;
    background-color: #ffffff !important;
}

#copyrights {
    background-color: #ffffff !important;
    font-size: 18px;
}

// ab hier alte Daten - können evtl. entfernt werden

.bg-color-p {
    background-color: $primary;
}
.bg-color-p2 {
    background-color: $primary2;
}
.bg-color-p3 {
    background-color: $primary3;
}

.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1450px;
}

.logo-slogan {
    display: none;
}

@include media-breakpoint-up(lg) {
    .logo-slogan {
        display: inline-block;
        color: $text-color;
    }
    
}

#primary-menu-trigger {
    display: flex;
}

.footer {
    margin-top: 20px;
    background-color: $primary3;
}
.footer-info {
    background-color: white;
    color: $text-color;
}
.footer-text {
    font-family: 'Varela Round', sans-serif;
    background-color: $primary3;
    color: $primary;
    a {
        color: $primary;
    }
}

// --------------- Blocks on Startpage

.slogan {
    //background-color: #f3f4f8;
    background: rgb(255,255,255);
    background: linear-gradient(198deg, rgba(255,255,255,1) 0%, rgba(219,200,148,1) 49%, rgba(222,226,230,1) 100%);
    padding: 3em;
    text-shadow: 1px 1px white;
    /*
    background-image: url('/images/diverse/armbrust-ruf-produktion.jpg');
    background-size: cover;
    background-position: center;
    */
    border: none;
    .sloganText {
        padding-top: 3em;
        padding-bottom: 3em;
    }
    .sloganTextohneHero {
        padding-top: 4em;
        padding-bottom: 0em;
    }
}

.fadeInDiv { animation: fadeIn 5s; }

.box1 {
    //background-color: #dee2e6;
    border-image: linear-gradient(to right, #dee2e6, #caad61, #dee2e6) 1;
    border-width: 4px;
    border-style: solid;
    border-left: none;
    border-right: none;
    border-bottom: none;
    padding: 10px;
    h2, a {
        color: $text-color !important;
    }
    padding: 20px;
}

.box2 {
    background-color: #f3f4f8;
    border-image: linear-gradient(to right, #f3f4f8, #caad61) 1;
    border-width: 4px;
    border-style: solid;
    border-left: none;
    border-right: none;
    border-bottom: none;
    padding: 10px;
    h2, a {
        color: $text-color !important;
    }
    padding: 20px;
}

.bRight {
    margin-left: 10px;
    background-color: #ffffff;
}

.bCenter {
    margin-right: 10px;
    background-color: #ffffff;
}

.bLeft {
    margin-right: 10px;
    background-color: #ffffff;
}

.pfBorder {
    border-image: linear-gradient(to right, #dee2e6, #caad61, #dee2e6) 1;
    border-width: 4px;
    border-style: solid;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.categories {
    font-size: 24px;
    padding-top: 30px;
}

.categoriesDummy {
    padding-bottom: 10px;
}

.service {
    font-size: 24px;
    background: rgb(235,209,139);
    background: linear-gradient(163deg, rgba(235,209,139,1) 0%, rgba(255,255,255,1) 45%, rgba(222,226,230,1) 100%);
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
    margin-bottom: 0 !important;
}

.firma {
    font-size: 24px;
    background: rgb(222,226,230);
    background: linear-gradient(22deg, rgba(222,226,230,1) 0%, rgba(255,255,255,1) 100%, rgba(240,224,184,0.9859653333989846) 100%);
    a:link {
        color: black !important;
    }
}

.katalog {
    font-size: 24px;
    padding-top: 3em;
    padding-bottom: 3em;
    background: rgb(222,226,230);
    background: linear-gradient(70deg, rgba(222,226,230,1) 0%, rgba(255,255,255,1) 49%, rgba(240,224,184,0.9859653333989846) 90%);
}

.b2b {
    font-size: 24px;
    //background: rgb(235,209,139);
    //background: linear-gradient(163deg, rgba(235,209,139,1) 0%, rgba(255,255,255,1) 45%, rgba(222,226,230,1) 100%);
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    margin-bottom: 0 !important;
}

 .b2bShop {
    font-size: 24px;
    background-color: #E8E8E8;
    padding-top: 1em !important;
    padding-bottom: 3em !important;
}

.kettenInfo {
    font-size: 24px;
    background: rgb(235,209,139);
    background: linear-gradient(163deg, rgba(235,209,139,1) 0%, rgba(255,255,255,1) 45%, rgba(222,226,230,1) 100%);
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
    margin-bottom: 0 !important;
}

.kontakt {
    font-size: 20px;
}

// --------------- Back to Top Button
.backToTop {
    position: fixed;
    bottom: 10px;
    left: 25px;
    display: none;
    padding: 10px;
    background-color: $primary;
    color: white;
    border-radius: 5px;
    &:hover {
        background-color: $primary3;
        color: black;
    }
}

.container-iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    //padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    //padding-top: 75%; /* 4:3 Aspect Ratio */
    //padding-top: 100%; /* 1:1 Aspect Ratio */
    padding-top: 50%; 
  }
  
  /* Then style the iframe to fit in the container div with full height and width */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

.icon-ar {
    background-color: white;
    color: $primary;
}  

@media screen and (min-width: 767px) {
    .slogan {
        .sloganTextohneHero {
            padding-top: 3em;
            padding-bottom: 3em;
        }
    
    }
}
