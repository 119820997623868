@charset "UTF-8";
/*
 blau-dunkel: #0059A7 
 blau-mittel: #AEBDDD
*/
/*!
 * Bootstrap v5.2.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import "../vendor/canvas/canvas-style.css";
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: hsl(358deg, 66%, 44%);
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 186, 38, 43;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-heading-color: ;
  --bs-link-color: hsl(358deg, 66%, 44%);
  --bs-link-hover-color: #951e22;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: rgba(var(--bs-body-color-rgb), 0.75);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #f1d4d5;
  --bs-table-border-color: #d9bfc0;
  --bs-table-striped-bg: #e5c9ca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d9bfc0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dfc4c5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: rgba(var(--bs-body-color-rgb), 0.75);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #dd9395;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(186, 38, 43, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.375rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #dd9395;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(186, 38, 43, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #dd9395;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(186, 38, 43, 0.25);
}
.form-check-input:checked {
  background-color: hsl(358deg, 66%, 44%);
  border-color: hsl(358deg, 66%, 44%);
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: hsl(358deg, 66%, 44%);
  border-color: hsl(358deg, 66%, 44%);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23dd9395'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(186, 38, 43, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(186, 38, 43, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: hsl(358deg, 66%, 44%);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #eabebf;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: hsl(358deg, 66%, 44%);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #eabebf;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check:focus + .btn, .btn:focus {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: hsl(358deg, 66%, 44%);
  --bs-btn-border-color: hsl(358deg, 66%, 44%);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #9e2025;
  --bs-btn-hover-border-color: #951e22;
  --bs-btn-focus-shadow-rgb: 196, 71, 75;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #951e22;
  --bs-btn-active-border-color: #8c1d20;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: hsl(358deg, 66%, 44%);
  --bs-btn-disabled-border-color: hsl(358deg, 66%, 44%);
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f9fafb;
  --bs-btn-hover-border-color: #f9fafb;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f9fafb;
  --bs-btn-active-border-color: #f9fafb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1c1f23;
  --bs-btn-hover-border-color: #1a1e21;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1a1e21;
  --bs-btn-active-border-color: #191c1f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: hsl(358deg, 66%, 44%);
  --bs-btn-border-color: hsl(358deg, 66%, 44%);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: hsl(358deg, 66%, 44%);
  --bs-btn-hover-border-color: hsl(358deg, 66%, 44%);
  --bs-btn-focus-shadow-rgb: 186, 38, 43;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: hsl(358deg, 66%, 44%);
  --bs-btn-active-border-color: hsl(358deg, 66%, 44%);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: hsl(358deg, 66%, 44%);
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  text-decoration: underline;
}
.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: hsl(358deg, 66%, 44%);
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: hsl(358deg, 66%, 44%);
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: hsl(358deg, 66%, 44%);
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #000;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a72227'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #dd9395;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(186, 38, 43, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #a72227;
  --bs-accordion-active-bg: #f8e9ea;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(186, 38, 43, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: hsl(358deg, 66%, 44%);
  --bs-pagination-active-border-color: hsl(358deg, 66%, 44%);
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius, 0);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius, 0);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: #70171a;
  --bs-alert-bg: #f1d4d5;
  --bs-alert-border-color: #eabebf;
}
.alert-primary .alert-link {
  color: #5a1215;
}

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: hsl(358deg, 66%, 44%);
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: hsl(358deg, 66%, 44%);
  --bs-list-group-active-border-color: hsl(358deg, 66%, 44%);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(var(--bs-list-group-border-width) * -1);
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(var(--bs-list-group-border-width) * -1);
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #70171a;
  background-color: #f1d4d5;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #70171a;
  background-color: #d9bfc0;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #70171a;
  border-color: #70171a;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(186, 38, 43, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  position: absolute;
  z-index: 1090;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(var(--bs-toast-padding-x) * -0.5);
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(var(--bs-modal-header-padding-y) * -0.5) calc(var(--bs-modal-header-padding-x) * -0.5) calc(var(--bs-modal-header-padding-y) * -0.5) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius, 0);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: var(--bs-heading-color);
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(var(--bs-popover-arrow-width) * -0.5);
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(var(--bs-offcanvas-padding-y) * -0.5);
  margin-right: calc(var(--bs-offcanvas-padding-x) * -0.5);
  margin-bottom: calc(var(--bs-offcanvas-padding-y) * -0.5);
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(186, 38, 43, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: hsl(358deg, 66%, 44%) !important;
}
.link-primary:hover, .link-primary:focus {
  color: #951e22 !important;
}

.link-secondary {
  color: #6c757d !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64 !important;
}

.link-success {
  color: #198754 !important;
}
.link-success:hover, .link-success:focus {
  color: #146c43 !important;
}

.link-info {
  color: #0dcaf0 !important;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #ffc107 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #dc3545 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f8f9fa !important;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), 0.75) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*
// Option B: Include parts of Bootstrap
// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Optional
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
*/
@font-face {
  font-family: "font-icons";
  src: url("fonts/font-icons.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "lined-icons";
  src: url("fonts/lined-icons.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Simple-Line-Icons";
  src: url("fonts/Simple-Line-Icons.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^=icon-],
[class*=" icon-"],
[class^=icon-line-],
[class*=" icon-line-"],
[class^=icon-line2-],
[class*=" icon-line2-"],
.side-header .primary-menu ul > li.sub-menu > a:after,
.docs-navigation ul ul li.current a:after {
  display: inline-block;
  font-family: "font-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=icon-line-],
[class*=" icon-line-"] {
  font-family: "lined-icons";
}

[class^=icon-line2-],
[class*=" icon-line2-"] {
  font-family: "Simple-Line-Icons";
}

.icon-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.icon-2x {
  font-size: 2em;
}

.icon-3x {
  font-size: 3em;
}

.icon-4x {
  font-size: 4em;
}

.icon-5x {
  font-size: 5em;
}

.icon-fw {
  width: 1.2857142857em;
  text-align: center;
}

.iconlist {
  padding-left: 0;
  margin-left: 0;
  list-style-type: none;
}

.iconlist li {
  position: relative;
  display: flex;
  align-items: baseline;
}

.iconlist ul {
  list-style-type: none;
  margin: 0.5rem 0 0.5rem 1.5rem;
}

.iconlist > li [class^=icon-]:first-child,
.iconlist > li [class*=" icon-"]:first-child {
  position: relative;
  text-align: center;
  top: 1px;
  width: 1rem;
  margin-right: 0.75rem;
  flex: 0 0 auto;
}

.rtl .iconlist > li [class^=icon-]:first-child,
.rtl .iconlist > li [class*=" icon-"]:first-child {
  margin-left: 0.75rem;
  margin-right: 0;
}

.iconlist.iconlist-large {
  font-size: 1.5rem;
}

.iconlist.iconlist-large li {
  margin: 4px 0;
}

.iconlist.iconlist-large > li [class^=icon-],
.iconlist.iconlist-large > li [class*=" icon-"] {
  width: 1.5rem;
  margin-right: 5px;
}

.rtl .iconlist.iconlist-large > li [class^=icon-],
.rtl .iconlist.iconlist-large > li [class*=" icon-"] {
  margin-left: 5px;
  margin-right: 0;
}

.iconlist > li [class^=icon-]:first-child {
  color: #1ABC9C;
  color: var(--themecolor);
}

.icon-border {
  padding: 0.2em 0.25em 0.15em;
  border: solid 0.08em #eeeeee;
  border-radius: 0.1em;
}

.icon.pull-left {
  margin-right: 0.3em;
}

.icon.pull-right {
  margin-left: 0.3em;
}

.icon-spin {
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.icon-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.icon-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}

.icon-stacked {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.icon-stacked-1x,
.icon-stacked-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.rtl .icon-stacked-1x,
.rtl .icon-stacked-2x {
  left: auto;
  right: 0;
}

.icon-stacked-1x {
  line-height: inherit;
}

.icon-stacked-2x {
  font-size: 2em;
}

.icon-inverse {
  color: #ffffff;
}

.icon-type:before {
  content: "\e600";
}

.icon-box1:before {
  content: "\e601";
}

.icon-archive1:before {
  content: "\e602";
}

.icon-envelope2:before {
  content: "\e603";
}

.icon-email:before {
  content: "\e604";
}

.icon-files:before {
  content: "\e605";
}

.icon-printer2:before {
  content: "\e606";
}

.icon-folder-add:before {
  content: "\e607";
}

.icon-folder-settings:before {
  content: "\e608";
}

.icon-folder-check:before {
  content: "\e609";
}

.icon-wifi-low:before {
  content: "\e60a";
}

.icon-wifi-mid:before {
  content: "\e60b";
}

.icon-wifi-full:before {
  content: "\e60c";
}

.icon-connection-empty:before {
  content: "\e60d";
}

.icon-battery-full1:before {
  content: "\e60e";
}

.icon-settings:before {
  content: "\e60f";
}

.icon-arrow-left1:before {
  content: "\e610";
}

.icon-arrow-up1:before {
  content: "\e611";
}

.icon-arrow-down1:before {
  content: "\e612";
}

.icon-arrow-right1:before {
  content: "\e613";
}

.icon-reload:before {
  content: "\e614";
}

.icon-download1:before {
  content: "\e615";
}

.icon-tag1:before {
  content: "\e616";
}

.icon-trashcan:before {
  content: "\e617";
}

.icon-search1:before {
  content: "\e618";
}

.icon-zoom-in:before {
  content: "\e619";
}

.icon-zoom-out:before {
  content: "\e61a";
}

.icon-chat:before {
  content: "\e61b";
}

.icon-clock2:before {
  content: "\e61c";
}

.icon-printer:before {
  content: "\e61d";
}

.icon-home1:before {
  content: "\e61e";
}

.icon-flag2:before {
  content: "\e61f";
}

.icon-meter:before {
  content: "\e620";
}

.icon-switch:before {
  content: "\e621";
}

.icon-forbidden:before {
  content: "\e622";
}

.icon-phone-landscape:before {
  content: "\e623";
}

.icon-tablet1:before {
  content: "\e624";
}

.icon-tablet-landscape:before {
  content: "\e625";
}

.icon-laptop1:before {
  content: "\e626";
}

.icon-camera1:before {
  content: "\e627";
}

.icon-microwave-oven:before {
  content: "\e628";
}

.icon-credit-cards:before {
  content: "\e629";
}

.icon-map-marker1:before {
  content: "\e62a";
}

.icon-map2:before {
  content: "\e62b";
}

.icon-support:before {
  content: "\e62c";
}

.icon-newspaper2:before {
  content: "\e62d";
}

.icon-barbell:before {
  content: "\e62e";
}

.icon-stopwatch1:before {
  content: "\e62f";
}

.icon-atom1:before {
  content: "\e630";
}

.icon-image2:before {
  content: "\e631";
}

.icon-cube1:before {
  content: "\e632";
}

.icon-bars1:before {
  content: "\e633";
}

.icon-chart:before {
  content: "\e634";
}

.icon-pencil:before {
  content: "\e635";
}

.icon-measure:before {
  content: "\e636";
}

.icon-eyedropper:before {
  content: "\e637";
}

.icon-file-settings:before {
  content: "\e638";
}

.icon-file-add:before {
  content: "\e639";
}

.icon-file2:before {
  content: "\e63a";
}

.icon-align-left1:before {
  content: "\e63b";
}

.icon-align-right1:before {
  content: "\e63c";
}

.icon-align-center1:before {
  content: "\e63d";
}

.icon-align-justify1:before {
  content: "\e63e";
}

.icon-file-broken:before {
  content: "\e63f";
}

.icon-browser:before {
  content: "\e640";
}

.icon-windows1:before {
  content: "\e641";
}

.icon-window:before {
  content: "\e642";
}

.icon-folder2:before {
  content: "\e643";
}

.icon-connection-25:before {
  content: "\e644";
}

.icon-connection-50:before {
  content: "\e645";
}

.icon-connection-75:before {
  content: "\e646";
}

.icon-connection-full:before {
  content: "\e647";
}

.icon-list1:before {
  content: "\e648";
}

.icon-grid:before {
  content: "\e649";
}

.icon-stack3:before {
  content: "\e64a";
}

.icon-battery-charging:before {
  content: "\e64b";
}

.icon-battery-empty1:before {
  content: "\e64c";
}

.icon-battery-25:before {
  content: "\e64d";
}

.icon-battery-50:before {
  content: "\e64e";
}

.icon-battery-75:before {
  content: "\e64f";
}

.icon-refresh:before {
  content: "\e650";
}

.icon-volume:before {
  content: "\e651";
}

.icon-volume-increase:before {
  content: "\e652";
}

.icon-volume-decrease:before {
  content: "\e653";
}

.icon-mute:before {
  content: "\e654";
}

.icon-microphone1:before {
  content: "\e655";
}

.icon-microphone-off:before {
  content: "\e656";
}

.icon-book1:before {
  content: "\e657";
}

.icon-checkmark:before {
  content: "\e658";
}

.icon-checkbox-checked:before {
  content: "\e659";
}

.icon-checkbox:before {
  content: "\e65a";
}

.icon-paperclip1:before {
  content: "\e65b";
}

.icon-chat-1:before {
  content: "\e65c";
}

.icon-chat-2:before {
  content: "\e65d";
}

.icon-chat-3:before {
  content: "\e65e";
}

.icon-comment2:before {
  content: "\e65f";
}

.icon-calendar2:before {
  content: "\e660";
}

.icon-bookmark2:before {
  content: "\e661";
}

.icon-email2:before {
  content: "\e662";
}

.icon-heart2:before {
  content: "\e663";
}

.icon-enter:before {
  content: "\e664";
}

.icon-cloud1:before {
  content: "\e665";
}

.icon-book2:before {
  content: "\e666";
}

.icon-star2:before {
  content: "\e667";
}

.icon-lock1:before {
  content: "\e668";
}

.icon-unlocked:before {
  content: "\e669";
}

.icon-unlocked2:before {
  content: "\e66a";
}

.icon-users1:before {
  content: "\e66b";
}

.icon-user2:before {
  content: "\e66c";
}

.icon-users2:before {
  content: "\e66d";
}

.icon-user21:before {
  content: "\e66e";
}

.icon-bullhorn1:before {
  content: "\e66f";
}

.icon-share1:before {
  content: "\e670";
}

.icon-screen:before {
  content: "\e671";
}

.icon-phone1:before {
  content: "\e672";
}

.icon-phone-portrait:before {
  content: "\e673";
}

.icon-calculator1:before {
  content: "\e674";
}

.icon-bag:before {
  content: "\e675";
}

.icon-diamond:before {
  content: "\e676";
}

.icon-drink:before {
  content: "\e677";
}

.icon-shorts:before {
  content: "\e678";
}

.icon-vcard:before {
  content: "\e679";
}

.icon-sun2:before {
  content: "\e67a";
}

.icon-bill:before {
  content: "\e67b";
}

.icon-coffee1:before {
  content: "\e67c";
}

.icon-tv2:before {
  content: "\e67d";
}

.icon-newspaper3:before {
  content: "\e67e";
}

.icon-stack:before {
  content: "\e67f";
}

.icon-syringe1:before {
  content: "\e680";
}

.icon-health:before {
  content: "\e681";
}

.icon-bolt1:before {
  content: "\e682";
}

.icon-pill:before {
  content: "\e683";
}

.icon-bones:before {
  content: "\e684";
}

.icon-lab:before {
  content: "\e685";
}

.icon-clipboard2:before {
  content: "\e686";
}

.icon-mug:before {
  content: "\e687";
}

.icon-bucket:before {
  content: "\e688";
}

.icon-select:before {
  content: "\e689";
}

.icon-graph:before {
  content: "\e68a";
}

.icon-crop1:before {
  content: "\e68b";
}

.icon-heart21:before {
  content: "\e68c";
}

.icon-cloud2:before {
  content: "\e68d";
}

.icon-star21:before {
  content: "\e68e";
}

.icon-pen1:before {
  content: "\e68f";
}

.icon-diamond2:before {
  content: "\e690";
}

.icon-display:before {
  content: "\e691";
}

.icon-paperplane:before {
  content: "\e692";
}

.icon-params:before {
  content: "\e693";
}

.icon-banknote:before {
  content: "\e694";
}

.icon-vynil:before {
  content: "\e695";
}

.icon-truck1:before {
  content: "\e696";
}

.icon-world:before {
  content: "\e697";
}

.icon-tv1:before {
  content: "\e698";
}

.icon-sound:before {
  content: "\e699";
}

.icon-video1:before {
  content: "\e69a";
}

.icon-trash1:before {
  content: "\e69b";
}

.icon-user3:before {
  content: "\e69c";
}

.icon-key1:before {
  content: "\e69d";
}

.icon-search2:before {
  content: "\e69e";
}

.icon-settings2:before {
  content: "\e69f";
}

.icon-camera2:before {
  content: "\e6a0";
}

.icon-tag2:before {
  content: "\e6a1";
}

.icon-lock2:before {
  content: "\e6a2";
}

.icon-bulb:before {
  content: "\e6a3";
}

.icon-location:before {
  content: "\e6a4";
}

.icon-eye2:before {
  content: "\e6a5";
}

.icon-bubble:before {
  content: "\e6a6";
}

.icon-stack2:before {
  content: "\e6a7";
}

.icon-cup:before {
  content: "\e6a8";
}

.icon-phone2:before {
  content: "\e6a9";
}

.icon-news:before {
  content: "\e6aa";
}

.icon-mail:before {
  content: "\e6ab";
}

.icon-like:before {
  content: "\e6ac";
}

.icon-photo:before {
  content: "\e6ad";
}

.icon-note:before {
  content: "\e6ae";
}

.icon-clock21:before {
  content: "\e6af";
}

.icon-data:before {
  content: "\e6b0";
}

.icon-music1:before {
  content: "\e6b1";
}

.icon-megaphone:before {
  content: "\e6b2";
}

.icon-study:before {
  content: "\e6b3";
}

.icon-lab2:before {
  content: "\e6b4";
}

.icon-food:before {
  content: "\e6b5";
}

.icon-t-shirt:before {
  content: "\e6b6";
}

.icon-fire1:before {
  content: "\e6b7";
}

.icon-clip:before {
  content: "\e6b8";
}

.icon-shop:before {
  content: "\e6b9";
}

.icon-calendar21:before {
  content: "\e6ba";
}

.icon-wallet1:before {
  content: "\e6bb";
}

.icon-glass:before {
  content: "\e6bc";
}

.icon-music2:before {
  content: "\e6bd";
}

.icon-search3:before {
  content: "\e6be";
}

.icon-envelope21:before {
  content: "\e6bf";
}

.icon-heart3:before {
  content: "\e6c0";
}

.icon-star3:before {
  content: "\e6c1";
}

.icon-star-empty:before {
  content: "\e6c2";
}

.icon-user4:before {
  content: "\e6c3";
}

.icon-film1:before {
  content: "\e6c4";
}

.icon-th-large1:before {
  content: "\e6c5";
}

.icon-th1:before {
  content: "\e6c6";
}

.icon-th-list1:before {
  content: "\e6c7";
}

.icon-ok:before {
  content: "\e6c8";
}

.icon-remove:before {
  content: "\e6c9";
}

.icon-zoom-in2:before {
  content: "\e6ca";
}

.icon-zoom-out2:before {
  content: "\e6cb";
}

.icon-off:before {
  content: "\e6cc";
}

.icon-signal1:before {
  content: "\e6cd";
}

.icon-cog1:before {
  content: "\e6ce";
}

.icon-trash2:before {
  content: "\e6cf";
}

.icon-home2:before {
  content: "\e6d0";
}

.icon-file21:before {
  content: "\e6d1";
}

.icon-time:before {
  content: "\e6d2";
}

.icon-road1:before {
  content: "\e6d3";
}

.icon-download-alt:before {
  content: "\e6d4";
}

.icon-download2:before {
  content: "\e6d5";
}

.icon-upload1:before {
  content: "\e6d6";
}

.icon-inbox1:before {
  content: "\e6d7";
}

.icon-play-circle2:before {
  content: "\e6d8";
}

.icon-repeat:before {
  content: "\e6d9";
}

.icon-refresh2:before {
  content: "\e6da";
}

.icon-list-alt2:before {
  content: "\e6db";
}

.icon-lock3:before {
  content: "\e6dc";
}

.icon-flag21:before {
  content: "\e6dd";
}

.icon-headphones1:before {
  content: "\e6de";
}

.icon-volume-off1:before {
  content: "\e6df";
}

.icon-volume-down1:before {
  content: "\e6e0";
}

.icon-volume-up1:before {
  content: "\e6e1";
}

.icon-qrcode1:before {
  content: "\e6e2";
}

.icon-barcode1:before {
  content: "\e6e3";
}

.icon-tag3:before {
  content: "\e6e4";
}

.icon-tags1:before {
  content: "\e6e5";
}

.icon-book3:before {
  content: "\e6e6";
}

.icon-bookmark21:before {
  content: "\e6e7";
}

.icon-print2:before {
  content: "\e6e8";
}

.icon-camera3:before {
  content: "\e6e9";
}

.icon-font1:before {
  content: "\e6ea";
}

.icon-bold1:before {
  content: "\e6eb";
}

.icon-italic1:before {
  content: "\e6ec";
}

.icon-text-height1:before {
  content: "\e6ed";
}

.icon-text-width1:before {
  content: "\e6ee";
}

.icon-align-left2:before {
  content: "\e6ef";
}

.icon-align-center2:before {
  content: "\e6f0";
}

.icon-align-right2:before {
  content: "\e6f1";
}

.icon-align-justify2:before {
  content: "\e6f2";
}

.icon-list2:before {
  content: "\e6f3";
}

.icon-indent-left:before {
  content: "\e6f4";
}

.icon-indent-right:before {
  content: "\e6f5";
}

.icon-facetime-video:before {
  content: "\e6f6";
}

.icon-picture:before {
  content: "\e6f7";
}

.icon-pencil2:before {
  content: "\e6f8";
}

.icon-map-marker2:before {
  content: "\e6f9";
}

.icon-adjust1:before {
  content: "\e6fa";
}

.icon-tint1:before {
  content: "\e6fb";
}

.icon-edit2:before {
  content: "\e6fc";
}

.icon-share2:before {
  content: "\e6fd";
}

.icon-check1:before {
  content: "\e6fe";
}

.icon-move:before {
  content: "\e6ff";
}

.icon-step-backward1:before {
  content: "\e700";
}

.icon-fast-backward1:before {
  content: "\e701";
}

.icon-backward1:before {
  content: "\e702";
}

.icon-play1:before {
  content: "\e703";
}

.icon-pause1:before {
  content: "\e704";
}

.icon-stop1:before {
  content: "\e705";
}

.icon-forward1:before {
  content: "\e706";
}

.icon-fast-forward1:before {
  content: "\e707";
}

.icon-step-forward1:before {
  content: "\e708";
}

.icon-eject1:before {
  content: "\e709";
}

.icon-chevron-left1:before {
  content: "\e70a";
}

.icon-chevron-right1:before {
  content: "\e70b";
}

.icon-plus-sign:before {
  content: "\e70c";
}

.icon-minus-sign:before {
  content: "\e70d";
}

.icon-remove-sign:before {
  content: "\e70e";
}

.icon-ok-sign:before {
  content: "\e70f";
}

.icon-question-sign:before {
  content: "\e710";
}

.icon-info-sign:before {
  content: "\e711";
}

.icon-screenshot:before {
  content: "\e712";
}

.icon-remove-circle:before {
  content: "\e713";
}

.icon-ok-circle:before {
  content: "\e714";
}

.icon-ban-circle:before {
  content: "\e715";
}

.icon-arrow-left2:before {
  content: "\e716";
}

.icon-arrow-right2:before {
  content: "\e717";
}

.icon-arrow-up2:before {
  content: "\e718";
}

.icon-arrow-down2:before {
  content: "\e719";
}

.icon-share-alt1:before {
  content: "\e71a";
}

.icon-resize-full:before {
  content: "\e71b";
}

.icon-resize-small:before {
  content: "\e71c";
}

.icon-plus1:before {
  content: "\e71d";
}

.icon-minus1:before {
  content: "\e71e";
}

.icon-asterisk1:before {
  content: "\e71f";
}

.icon-exclamation-sign:before {
  content: "\e720";
}

.icon-gift1:before {
  content: "\e721";
}

.icon-leaf1:before {
  content: "\e722";
}

.icon-fire2:before {
  content: "\e723";
}

.icon-eye-open:before {
  content: "\e724";
}

.icon-eye-close:before {
  content: "\e725";
}

.icon-warning-sign:before {
  content: "\e726";
}

.icon-plane1:before {
  content: "\e727";
}

.icon-calendar3:before {
  content: "\e728";
}

.icon-random1:before {
  content: "\e729";
}

.icon-comment21:before {
  content: "\e72a";
}

.icon-magnet1:before {
  content: "\e72b";
}

.icon-chevron-up1:before {
  content: "\e72c";
}

.icon-chevron-down1:before {
  content: "\e72d";
}

.icon-retweet1:before {
  content: "\e72e";
}

.icon-shopping-cart:before {
  content: "\e72f";
}

.icon-folder-close:before {
  content: "\e730";
}

.icon-folder-open2:before {
  content: "\e731";
}

.icon-resize-vertical:before {
  content: "\e732";
}

.icon-resize-horizontal:before {
  content: "\e733";
}

.icon-bar-chart:before {
  content: "\e734";
}

.icon-twitter-sign:before {
  content: "\e735";
}

.icon-facebook-sign:before {
  content: "\e736";
}

.icon-camera-retro1:before {
  content: "\e737";
}

.icon-key2:before {
  content: "\e738";
}

.icon-cogs1:before {
  content: "\e739";
}

.icon-comments2:before {
  content: "\e73a";
}

.icon-thumbs-up2:before {
  content: "\e73b";
}

.icon-thumbs-down2:before {
  content: "\e73c";
}

.icon-star-half2:before {
  content: "\e73d";
}

.icon-heart-empty:before {
  content: "\e73e";
}

.icon-signout:before {
  content: "\e73f";
}

.icon-linkedin-sign:before {
  content: "\e740";
}

.icon-pushpin:before {
  content: "\e741";
}

.icon-external-link:before {
  content: "\e742";
}

.icon-signin:before {
  content: "\e743";
}

.icon-trophy1:before {
  content: "\e744";
}

.icon-github-sign:before {
  content: "\e745";
}

.icon-upload-alt:before {
  content: "\e746";
}

.icon-lemon2:before {
  content: "\e747";
}

.icon-phone3:before {
  content: "\e748";
}

.icon-check-empty:before {
  content: "\e749";
}

.icon-bookmark-empty:before {
  content: "\e74a";
}

.icon-phone-sign:before {
  content: "\e74b";
}

.icon-twitter2:before {
  content: "\e74c";
}

.icon-facebook2:before {
  content: "\e74d";
}

.icon-github2:before {
  content: "\e74e";
}

.icon-unlock1:before {
  content: "\e74f";
}

.icon-credit:before {
  content: "\e750";
}

.icon-rss2:before {
  content: "\e751";
}

.icon-hdd2:before {
  content: "\e752";
}

.icon-bullhorn2:before {
  content: "\e753";
}

.icon-bell2:before {
  content: "\e754";
}

.icon-certificate1:before {
  content: "\e755";
}

.icon-hand-right:before {
  content: "\e756";
}

.icon-hand-left:before {
  content: "\e757";
}

.icon-hand-up:before {
  content: "\e758";
}

.icon-hand-down:before {
  content: "\e759";
}

.icon-circle-arrow-left:before {
  content: "\e75a";
}

.icon-circle-arrow-right:before {
  content: "\e75b";
}

.icon-circle-arrow-up:before {
  content: "\e75c";
}

.icon-circle-arrow-down:before {
  content: "\e75d";
}

.icon-globe1:before {
  content: "\e75e";
}

.icon-wrench1:before {
  content: "\e75f";
}

.icon-tasks1:before {
  content: "\e760";
}

.icon-filter1:before {
  content: "\e761";
}

.icon-briefcase1:before {
  content: "\e762";
}

.icon-fullscreen:before {
  content: "\e763";
}

.icon-group:before {
  content: "\e764";
}

.icon-link1:before {
  content: "\e765";
}

.icon-cloud3:before {
  content: "\e766";
}

.icon-beaker:before {
  content: "\e767";
}

.icon-cut1:before {
  content: "\e768";
}

.icon-copy2:before {
  content: "\e769";
}

.icon-paper-clip:before {
  content: "\e76a";
}

.icon-save2:before {
  content: "\e76b";
}

.icon-sign-blank:before {
  content: "\e76c";
}

.icon-reorder:before {
  content: "\e76d";
}

.icon-list-ul1:before {
  content: "\e76e";
}

.icon-list-ol1:before {
  content: "\e76f";
}

.icon-strikethrough1:before {
  content: "\e770";
}

.icon-underline1:before {
  content: "\e771";
}

.icon-table1:before {
  content: "\e772";
}

.icon-magic1:before {
  content: "\e773";
}

.icon-truck2:before {
  content: "\e774";
}

.icon-pinterest2:before {
  content: "\e775";
}

.icon-pinterest-sign:before {
  content: "\e776";
}

.icon-google-plus-sign:before {
  content: "\e777";
}

.icon-google-plus1:before {
  content: "\e778";
}

.icon-money:before {
  content: "\e779";
}

.icon-caret-down1:before {
  content: "\e77a";
}

.icon-caret-up1:before {
  content: "\e77b";
}

.icon-caret-left1:before {
  content: "\e77c";
}

.icon-caret-right1:before {
  content: "\e77d";
}

.icon-columns1:before {
  content: "\e77e";
}

.icon-sort1:before {
  content: "\e77f";
}

.icon-sort-down1:before {
  content: "\e780";
}

.icon-sort-up1:before {
  content: "\e781";
}

.icon-envelope-alt:before {
  content: "\e782";
}

.icon-linkedin2:before {
  content: "\e783";
}

.icon-undo1:before {
  content: "\e784";
}

.icon-legal:before {
  content: "\e785";
}

.icon-dashboard:before {
  content: "\e786";
}

.icon-comment-alt2:before {
  content: "\e787";
}

.icon-comments-alt:before {
  content: "\e788";
}

.icon-bolt2:before {
  content: "\e789";
}

.icon-sitemap1:before {
  content: "\e78a";
}

.icon-umbrella1:before {
  content: "\e78b";
}

.icon-paste1:before {
  content: "\e78c";
}

.icon-lightbulb2:before {
  content: "\e78d";
}

.icon-exchange:before {
  content: "\e78e";
}

.icon-cloud-download:before {
  content: "\e78f";
}

.icon-cloud-upload:before {
  content: "\e790";
}

.icon-user-md1:before {
  content: "\e791";
}

.icon-stethoscope1:before {
  content: "\e792";
}

.icon-suitcase1:before {
  content: "\e793";
}

.icon-bell-alt:before {
  content: "\e794";
}

.icon-coffee2:before {
  content: "\e795";
}

.icon-food2:before {
  content: "\e796";
}

.icon-file-alt2:before {
  content: "\e797";
}

.icon-building2:before {
  content: "\e798";
}

.icon-hospital2:before {
  content: "\e799";
}

.icon-ambulance1:before {
  content: "\e79a";
}

.icon-medkit1:before {
  content: "\e79b";
}

.icon-fighter-jet1:before {
  content: "\e79c";
}

.icon-beer1:before {
  content: "\e79d";
}

.icon-h-sign:before {
  content: "\e79e";
}

.icon-plus-sign2:before {
  content: "\e79f";
}

.icon-double-angle-left:before {
  content: "\e7a0";
}

.icon-double-angle-right:before {
  content: "\e7a1";
}

.icon-double-angle-up:before {
  content: "\e7a2";
}

.icon-double-angle-down:before {
  content: "\e7a3";
}

.icon-angle-left:before {
  content: "\e7a4";
}

.icon-angle-right:before {
  content: "\e7a5";
}

.icon-angle-up:before {
  content: "\e7a6";
}

.icon-angle-down:before {
  content: "\e7a7";
}

.icon-desktop1:before {
  content: "\e7a8";
}

.icon-laptop2:before {
  content: "\e7a9";
}

.icon-tablet2:before {
  content: "\e7aa";
}

.icon-mobile1:before {
  content: "\e7ab";
}

.icon-circle-blank:before {
  content: "\e7ac";
}

.icon-quote-left1:before {
  content: "\e7ad";
}

.icon-quote-right1:before {
  content: "\e7ae";
}

.icon-spinner1:before {
  content: "\e7af";
}

.icon-circle2:before {
  content: "\e7b0";
}

.icon-reply1:before {
  content: "\e7b1";
}

.icon-github-alt1:before {
  content: "\e7b2";
}

.icon-folder-close-alt:before {
  content: "\e7b3";
}

.icon-folder-open-alt:before {
  content: "\e7b4";
}

.icon-expand-alt:before {
  content: "\e7b5";
}

.icon-collapse-alt:before {
  content: "\e7b6";
}

.icon-smile2:before {
  content: "\e7b7";
}

.icon-frown2:before {
  content: "\e7b8";
}

.icon-meh2:before {
  content: "\e7b9";
}

.icon-gamepad1:before {
  content: "\e7ba";
}

.icon-keyboard2:before {
  content: "\e7bb";
}

.icon-flag-alt:before {
  content: "\e7bc";
}

.icon-flag-checkered1:before {
  content: "\e7bd";
}

.icon-terminal1:before {
  content: "\e7be";
}

.icon-code1:before {
  content: "\e7bf";
}

.icon-reply-all1:before {
  content: "\e7c0";
}

.icon-star-half-full:before {
  content: "\e7c1";
}

.icon-location-arrow1:before {
  content: "\e7c2";
}

.icon-crop2:before {
  content: "\e7c3";
}

.icon-code-fork:before {
  content: "\e7c4";
}

.icon-unlink1:before {
  content: "\e7c5";
}

.icon-question1:before {
  content: "\e7c6";
}

.icon-info1:before {
  content: "\e7c7";
}

.icon-exclamation1:before {
  content: "\e7c8";
}

.icon-superscript1:before {
  content: "\e7c9";
}

.icon-subscript1:before {
  content: "\e7ca";
}

.icon-eraser1:before {
  content: "\e7cb";
}

.icon-puzzle:before {
  content: "\e7cc";
}

.icon-microphone2:before {
  content: "\e7cd";
}

.icon-microphone-off2:before {
  content: "\e7ce";
}

.icon-shield:before {
  content: "\e7cf";
}

.icon-calendar-empty:before {
  content: "\e7d0";
}

.icon-fire-extinguisher1:before {
  content: "\e7d1";
}

.icon-rocket1:before {
  content: "\e7d2";
}

.icon-maxcdn1:before {
  content: "\e7d3";
}

.icon-chevron-sign-left:before {
  content: "\e7d4";
}

.icon-chevron-sign-right:before {
  content: "\e7d5";
}

.icon-chevron-sign-up:before {
  content: "\e7d6";
}

.icon-chevron-sign-down:before {
  content: "\e7d7";
}

.icon-html52:before {
  content: "\e7d8";
}

.icon-css31:before {
  content: "\e7d9";
}

.icon-anchor1:before {
  content: "\e7da";
}

.icon-unlock-alt1:before {
  content: "\e7db";
}

.icon-bullseye1:before {
  content: "\e7dc";
}

.icon-ellipsis-horizontal:before {
  content: "\e7dd";
}

.icon-ellipsis-vertical:before {
  content: "\e7de";
}

.icon-rss-sign:before {
  content: "\e7df";
}

.icon-play-sign:before {
  content: "\e7e0";
}

.icon-ticket:before {
  content: "\e7e1";
}

.icon-minus-sign-alt:before {
  content: "\e7e2";
}

.icon-check-minus:before {
  content: "\e7e3";
}

.icon-level-up:before {
  content: "\e7e4";
}

.icon-level-down:before {
  content: "\e7e5";
}

.icon-check-sign:before {
  content: "\e7e6";
}

.icon-edit-sign:before {
  content: "\e7e7";
}

.icon-external-link-sign:before {
  content: "\e7e8";
}

.icon-share-sign:before {
  content: "\e7e9";
}

.icon-compass2:before {
  content: "\e7ea";
}

.icon-collapse:before {
  content: "\e7eb";
}

.icon-collapse-top:before {
  content: "\e7ec";
}

.icon-expand1:before {
  content: "\e7ed";
}

.icon-euro:before {
  content: "\e7ee";
}

.icon-gbp:before {
  content: "\e7ef";
}

.icon-dollar:before {
  content: "\e7f0";
}

.icon-rupee:before {
  content: "\e7f1";
}

.icon-yen:before {
  content: "\e7f2";
}

.icon-renminbi:before {
  content: "\e7f3";
}

.icon-won:before {
  content: "\e7f4";
}

.icon-bitcoin2:before {
  content: "\e7f5";
}

.icon-file3:before {
  content: "\e7f6";
}

.icon-file-text:before {
  content: "\e7f7";
}

.icon-sort-by-alphabet:before {
  content: "\e7f8";
}

.icon-sort-by-alphabet-alt:before {
  content: "\e7f9";
}

.icon-sort-by-attributes:before {
  content: "\e7fa";
}

.icon-sort-by-attributes-alt:before {
  content: "\e7fb";
}

.icon-sort-by-order:before {
  content: "\e7fc";
}

.icon-sort-by-order-alt:before {
  content: "\e7fd";
}

.icon-thumbs-up21:before {
  content: "\e7fe";
}

.icon-thumbs-down21:before {
  content: "\e7ff";
}

.icon-youtube-sign:before {
  content: "\e800";
}

.icon-youtube2:before {
  content: "\e801";
}

.icon-xing2:before {
  content: "\e802";
}

.icon-xing-sign:before {
  content: "\e803";
}

.icon-youtube-play:before {
  content: "\e804";
}

.icon-dropbox2:before {
  content: "\e805";
}

.icon-stackexchange:before {
  content: "\e806";
}

.icon-instagram2:before {
  content: "\e807";
}

.icon-flickr2:before {
  content: "\e808";
}

.icon-adn1:before {
  content: "\e809";
}

.icon-bitbucket2:before {
  content: "\e80a";
}

.icon-bitbucket-sign:before {
  content: "\e80b";
}

.icon-tumblr2:before {
  content: "\e80c";
}

.icon-tumblr-sign:before {
  content: "\e80d";
}

.icon-long-arrow-down:before {
  content: "\e80e";
}

.icon-long-arrow-up:before {
  content: "\e80f";
}

.icon-long-arrow-left:before {
  content: "\e810";
}

.icon-long-arrow-right:before {
  content: "\e811";
}

.icon-apple1:before {
  content: "\e812";
}

.icon-windows3:before {
  content: "\e813";
}

.icon-android2:before {
  content: "\e814";
}

.icon-linux1:before {
  content: "\e815";
}

.icon-dribbble2:before {
  content: "\e816";
}

.icon-skype2:before {
  content: "\e817";
}

.icon-foursquare2:before {
  content: "\e818";
}

.icon-trello1:before {
  content: "\e819";
}

.icon-female1:before {
  content: "\e81a";
}

.icon-male1:before {
  content: "\e81b";
}

.icon-gittip:before {
  content: "\e81c";
}

.icon-sun21:before {
  content: "\e81d";
}

.icon-moon2:before {
  content: "\e81e";
}

.icon-archive2:before {
  content: "\e81f";
}

.icon-bug1:before {
  content: "\e820";
}

.icon-renren1:before {
  content: "\e821";
}

.icon-weibo2:before {
  content: "\e822";
}

.icon-vk2:before {
  content: "\e823";
}

.icon-duckduckgo:before {
  content: "\e830";
}

.icon-aim:before {
  content: "\e831";
}

.icon-delicious1:before {
  content: "\e832";
}

.icon-paypal1:before {
  content: "\e833";
}

.icon-flattr:before {
  content: "\e834";
}

.icon-android1:before {
  content: "\e835";
}

.icon-eventful:before {
  content: "\e836";
}

.icon-smashmag:before {
  content: "\e837";
}

.icon-gplus:before {
  content: "\e838";
}

.icon-wikipedia:before {
  content: "\e839";
}

.icon-lanyrd:before {
  content: "\e83a";
}

.icon-calendar-1:before {
  content: "\e83b";
}

.icon-stumbleupon1:before {
  content: "\e83c";
}

.icon-fivehundredpx:before {
  content: "\e83d";
}

.icon-pinterest1:before {
  content: "\e83e";
}

.icon-bitcoin1:before {
  content: "\e83f";
}

.icon-w3c:before {
  content: "\e840";
}

.icon-foursquare1:before {
  content: "\e841";
}

.icon-html51:before {
  content: "\e842";
}

.icon-ie:before {
  content: "\e843";
}

.icon-call:before {
  content: "\e844";
}

.icon-grooveshark:before {
  content: "\e845";
}

.icon-ninetyninedesigns:before {
  content: "\e846";
}

.icon-forrst:before {
  content: "\e847";
}

.icon-digg1:before {
  content: "\e848";
}

.icon-spotify1:before {
  content: "\e849";
}

.icon-reddit1:before {
  content: "\e84a";
}

.icon-guest:before {
  content: "\e84b";
}

.icon-gowalla:before {
  content: "\e84c";
}

.icon-appstore:before {
  content: "\e84d";
}

.icon-blogger1:before {
  content: "\e84e";
}

.icon-cc:before {
  content: "\e84f";
}

.icon-dribbble1:before {
  content: "\e850";
}

.icon-evernote:before {
  content: "\e851";
}

.icon-flickr1:before {
  content: "\e852";
}

.icon-google1:before {
  content: "\e853";
}

.icon-viadeo1:before {
  content: "\e854";
}

.icon-instapaper:before {
  content: "\e855";
}

.icon-weibo1:before {
  content: "\e856";
}

.icon-klout:before {
  content: "\e857";
}

.icon-linkedin1:before {
  content: "\e858";
}

.icon-meetup1:before {
  content: "\e859";
}

.icon-vk1:before {
  content: "\e85a";
}

.icon-plancast:before {
  content: "\e85b";
}

.icon-disqus:before {
  content: "\e85c";
}

.icon-rss1:before {
  content: "\e85d";
}

.icon-skype1:before {
  content: "\e85e";
}

.icon-twitter1:before {
  content: "\e85f";
}

.icon-youtube1:before {
  content: "\e860";
}

.icon-vimeo1:before {
  content: "\e861";
}

.icon-windows2:before {
  content: "\e862";
}

.icon-xing1:before {
  content: "\e863";
}

.icon-yahoo1:before {
  content: "\e864";
}

.icon-chrome1:before {
  content: "\e865";
}

.icon-email3:before {
  content: "\e866";
}

.icon-macstore:before {
  content: "\e867";
}

.icon-myspace:before {
  content: "\e868";
}

.icon-podcast1:before {
  content: "\e869";
}

.icon-amazon1:before {
  content: "\e86a";
}

.icon-steam1:before {
  content: "\e86b";
}

.icon-cloudapp:before {
  content: "\e86c";
}

.icon-dropbox1:before {
  content: "\e86d";
}

.icon-ebay1:before {
  content: "\e86e";
}

.icon-facebook:before {
  content: "\e86f";
}

.icon-github1:before {
  content: "\e870";
}

.icon-github-circled:before {
  content: "\e871";
}

.icon-googleplay:before {
  content: "\e872";
}

.icon-itunes1:before {
  content: "\e873";
}

.icon-plurk:before {
  content: "\e874";
}

.icon-songkick:before {
  content: "\e875";
}

.icon-lastfm1:before {
  content: "\e876";
}

.icon-gmail:before {
  content: "\e877";
}

.icon-pinboard:before {
  content: "\e878";
}

.icon-openid1:before {
  content: "\e879";
}

.icon-quora1:before {
  content: "\e87a";
}

.icon-soundcloud1:before {
  content: "\e87b";
}

.icon-tumblr1:before {
  content: "\e87c";
}

.icon-eventasaurus:before {
  content: "\e87d";
}

.icon-wordpress1:before {
  content: "\e87e";
}

.icon-yelp1:before {
  content: "\e87f";
}

.icon-intensedebate:before {
  content: "\e880";
}

.icon-eventbrite:before {
  content: "\e881";
}

.icon-scribd1:before {
  content: "\e882";
}

.icon-posterous:before {
  content: "\e883";
}

.icon-stripe1:before {
  content: "\e884";
}

.icon-opentable:before {
  content: "\e885";
}

.icon-cart:before {
  content: "\e886";
}

.icon-print1:before {
  content: "\e887";
}

.icon-angellist1:before {
  content: "\e888";
}

.icon-instagram1:before {
  content: "\e889";
}

.icon-dwolla:before {
  content: "\e88a";
}

.icon-appnet:before {
  content: "\e88b";
}

.icon-statusnet:before {
  content: "\e88c";
}

.icon-acrobat:before {
  content: "\e88d";
}

.icon-drupal1:before {
  content: "\e88e";
}

.icon-buffer:before {
  content: "\e88f";
}

.icon-pocket:before {
  content: "\e890";
}

.icon-bitbucket1:before {
  content: "\e891";
}

.icon-lego:before {
  content: "\e892";
}

.icon-login:before {
  content: "\e893";
}

.icon-stackoverflow:before {
  content: "\e894";
}

.icon-hackernews:before {
  content: "\e895";
}

.icon-lkdto:before {
  content: "\e896";
}

.icon-ad:before {
  content: "\e900";
}

.icon-address-book:before {
  content: "\e901";
}

.icon-address-card:before {
  content: "\e902";
}

.icon-adjust:before {
  content: "\e903";
}

.icon-air-freshener:before {
  content: "\e904";
}

.icon-align-center:before {
  content: "\e905";
}

.icon-align-justify:before {
  content: "\e906";
}

.icon-align-left:before {
  content: "\e907";
}

.icon-align-right:before {
  content: "\e908";
}

.icon-allergies:before {
  content: "\e909";
}

.icon-ambulance:before {
  content: "\e90a";
}

.icon-american-sign-language-interpreting:before {
  content: "\e90b";
}

.icon-anchor:before {
  content: "\e90c";
}

.icon-angle-double-down:before {
  content: "\e90d";
}

.icon-angle-double-left:before {
  content: "\e90e";
}

.icon-angle-double-right:before {
  content: "\e90f";
}

.icon-angle-double-up:before {
  content: "\e910";
}

.icon-angle-down1:before {
  content: "\e911";
}

.icon-angle-left1:before {
  content: "\e912";
}

.icon-angle-right1:before {
  content: "\e913";
}

.icon-angle-up1:before {
  content: "\e914";
}

.icon-angry:before {
  content: "\e915";
}

.icon-ankh:before {
  content: "\e916";
}

.icon-apple-alt:before {
  content: "\e917";
}

.icon-archive:before {
  content: "\e918";
}

.icon-archway:before {
  content: "\e919";
}

.icon-arrow-alt-circle-down:before {
  content: "\e91a";
}

.icon-arrow-alt-circle-left:before {
  content: "\e91b";
}

.icon-arrow-alt-circle-right:before {
  content: "\e91c";
}

.icon-arrow-alt-circle-up:before {
  content: "\e91d";
}

.icon-arrow-circle-down:before {
  content: "\e91e";
}

.icon-arrow-circle-left:before {
  content: "\e91f";
}

.icon-arrow-circle-right:before {
  content: "\e920";
}

.icon-arrow-circle-up:before {
  content: "\e921";
}

.icon-arrow-down:before {
  content: "\e922";
}

.icon-arrow-left:before {
  content: "\e923";
}

.icon-arrow-right:before {
  content: "\e924";
}

.icon-arrow-up:before {
  content: "\e925";
}

.icon-arrows-alt-h:before {
  content: "\e926";
}

.icon-arrows-alt-v:before {
  content: "\e927";
}

.icon-arrows-alt:before {
  content: "\e928";
}

.icon-assistive-listening-systems:before {
  content: "\e929";
}

.icon-asterisk:before {
  content: "\e92a";
}

.icon-at:before {
  content: "\e92b";
}

.icon-atlas:before {
  content: "\e92c";
}

.icon-atom:before {
  content: "\e92d";
}

.icon-audio-description:before {
  content: "\e92e";
}

.icon-award:before {
  content: "\e92f";
}

.icon-backspace:before {
  content: "\e930";
}

.icon-backward:before {
  content: "\e931";
}

.icon-balance-scale:before {
  content: "\e932";
}

.icon-ban:before {
  content: "\e933";
}

.icon-band-aid:before {
  content: "\e934";
}

.icon-barcode:before {
  content: "\e935";
}

.icon-bars:before {
  content: "\e936";
}

.icon-baseball-ball:before {
  content: "\e937";
}

.icon-basketball-ball:before {
  content: "\e938";
}

.icon-bath:before {
  content: "\e939";
}

.icon-battery-empty:before {
  content: "\e93a";
}

.icon-battery-full:before {
  content: "\e93b";
}

.icon-battery-half:before {
  content: "\e93c";
}

.icon-battery-quarter:before {
  content: "\e93d";
}

.icon-battery-three-quarters:before {
  content: "\e93e";
}

.icon-bed:before {
  content: "\e93f";
}

.icon-beer:before {
  content: "\e940";
}

.icon-bell-slash:before {
  content: "\e941";
}

.icon-bell:before {
  content: "\e942";
}

.icon-bezier-curve:before {
  content: "\e943";
}

.icon-bible:before {
  content: "\e944";
}

.icon-bicycle:before {
  content: "\e945";
}

.icon-binoculars:before {
  content: "\e946";
}

.icon-birthday-cake:before {
  content: "\e947";
}

.icon-blender:before {
  content: "\e948";
}

.icon-blind:before {
  content: "\e949";
}

.icon-bold:before {
  content: "\e94a";
}

.icon-bolt:before {
  content: "\e94b";
}

.icon-bomb:before {
  content: "\e94c";
}

.icon-bone:before {
  content: "\e94d";
}

.icon-bong:before {
  content: "\e94e";
}

.icon-book-open:before {
  content: "\e94f";
}

.icon-book-reader:before {
  content: "\e950";
}

.icon-book:before {
  content: "\e951";
}

.icon-bookmark:before {
  content: "\e952";
}

.icon-bowling-ball:before {
  content: "\e953";
}

.icon-box-open:before {
  content: "\e954";
}

.icon-box:before {
  content: "\e955";
}

.icon-boxes:before {
  content: "\e956";
}

.icon-braille:before {
  content: "\e957";
}

.icon-brain:before {
  content: "\e958";
}

.icon-briefcase-medical:before {
  content: "\e959";
}

.icon-briefcase:before {
  content: "\e95a";
}

.icon-broadcast-tower:before {
  content: "\e95b";
}

.icon-broom:before {
  content: "\e95c";
}

.icon-brush:before {
  content: "\e95d";
}

.icon-bug:before {
  content: "\e95e";
}

.icon-building:before {
  content: "\e95f";
}

.icon-bullhorn:before {
  content: "\e960";
}

.icon-bullseye:before {
  content: "\e961";
}

.icon-burn:before {
  content: "\e962";
}

.icon-bus-alt:before {
  content: "\e963";
}

.icon-bus:before {
  content: "\e964";
}

.icon-business-time:before {
  content: "\e965";
}

.icon-calculator:before {
  content: "\e966";
}

.icon-calendar-alt:before {
  content: "\e967";
}

.icon-calendar-check:before {
  content: "\e968";
}

.icon-calendar-minus:before {
  content: "\e969";
}

.icon-calendar-plus:before {
  content: "\e96a";
}

.icon-calendar-times:before {
  content: "\e96b";
}

.icon-calendar:before {
  content: "\e96c";
}

.icon-camera-retro:before {
  content: "\e96d";
}

.icon-camera:before {
  content: "\e96e";
}

.icon-cannabis:before {
  content: "\e96f";
}

.icon-capsules:before {
  content: "\e970";
}

.icon-car-alt:before {
  content: "\e971";
}

.icon-car-battery:before {
  content: "\e972";
}

.icon-car-crash:before {
  content: "\e973";
}

.icon-car-side:before {
  content: "\e974";
}

.icon-car:before {
  content: "\e975";
}

.icon-caret-down:before {
  content: "\e976";
}

.icon-caret-left:before {
  content: "\e977";
}

.icon-caret-right:before {
  content: "\e978";
}

.icon-caret-square-down:before {
  content: "\e979";
}

.icon-caret-square-left:before {
  content: "\e97a";
}

.icon-caret-square-right:before {
  content: "\e97b";
}

.icon-caret-square-up:before {
  content: "\e97c";
}

.icon-caret-up:before {
  content: "\e97d";
}

.icon-cart-arrow-down:before {
  content: "\e97e";
}

.icon-cart-plus:before {
  content: "\e97f";
}

.icon-certificate:before {
  content: "\e980";
}

.icon-chalkboard-teacher:before {
  content: "\e981";
}

.icon-chalkboard:before {
  content: "\e982";
}

.icon-charging-station:before {
  content: "\e983";
}

.icon-chart-area:before {
  content: "\e984";
}

.icon-chart-bar:before {
  content: "\e985";
}

.icon-chart-line:before {
  content: "\e986";
}

.icon-chart-pie:before {
  content: "\e987";
}

.icon-check-circle:before {
  content: "\e988";
}

.icon-check-double:before {
  content: "\e989";
}

.icon-check-square:before {
  content: "\e98a";
}

.icon-check:before {
  content: "\e98b";
}

.icon-chess-bishop:before {
  content: "\e98c";
}

.icon-chess-board:before {
  content: "\e98d";
}

.icon-chess-king:before {
  content: "\e98e";
}

.icon-chess-knight:before {
  content: "\e98f";
}

.icon-chess-pawn:before {
  content: "\e990";
}

.icon-chess-queen:before {
  content: "\e991";
}

.icon-chess-rook:before {
  content: "\e992";
}

.icon-chess:before {
  content: "\e993";
}

.icon-chevron-circle-down:before {
  content: "\e994";
}

.icon-chevron-circle-left:before {
  content: "\e995";
}

.icon-chevron-circle-right:before {
  content: "\e996";
}

.icon-chevron-circle-up:before {
  content: "\e997";
}

.icon-chevron-down:before {
  content: "\e998";
}

.icon-chevron-left:before {
  content: "\e999";
}

.icon-chevron-right:before {
  content: "\e99a";
}

.icon-chevron-up:before {
  content: "\e99b";
}

.icon-child:before {
  content: "\e99c";
}

.icon-church:before {
  content: "\e99d";
}

.icon-circle-notch:before {
  content: "\e99e";
}

.icon-circle:before {
  content: "\e99f";
}

.icon-city:before {
  content: "\e9a0";
}

.icon-clipboard-check:before {
  content: "\e9a1";
}

.icon-clipboard-list:before {
  content: "\e9a2";
}

.icon-clipboard:before {
  content: "\e9a3";
}

.icon-clock:before {
  content: "\e9a4";
}

.icon-clone:before {
  content: "\e9a5";
}

.icon-closed-captioning:before {
  content: "\e9a6";
}

.icon-cloud-download-alt:before {
  content: "\e9a7";
}

.icon-cloud-upload-alt:before {
  content: "\e9a8";
}

.icon-cloud:before {
  content: "\e9a9";
}

.icon-cocktail:before {
  content: "\e9aa";
}

.icon-code-branch:before {
  content: "\e9ab";
}

.icon-code:before {
  content: "\e9ac";
}

.icon-coffee:before {
  content: "\e9ad";
}

.icon-cog:before {
  content: "\e9ae";
}

.icon-cogs:before {
  content: "\e9af";
}

.icon-coins:before {
  content: "\e9b0";
}

.icon-columns:before {
  content: "\e9b1";
}

.icon-comment-alt:before {
  content: "\e9b2";
}

.icon-comment-dollar:before {
  content: "\e9b3";
}

.icon-comment-dots:before {
  content: "\e9b4";
}

.icon-comment-slash:before {
  content: "\e9b5";
}

.icon-comment:before {
  content: "\e9b6";
}

.icon-comments-dollar:before {
  content: "\e9b7";
}

.icon-comments:before {
  content: "\e9b8";
}

.icon-compact-disc:before {
  content: "\e9b9";
}

.icon-compass:before {
  content: "\e9ba";
}

.icon-compress:before {
  content: "\e9bb";
}

.icon-concierge-bell:before {
  content: "\e9bc";
}

.icon-cookie-bite:before {
  content: "\e9bd";
}

.icon-cookie:before {
  content: "\e9be";
}

.icon-copy:before {
  content: "\e9bf";
}

.icon-copyright:before {
  content: "\e9c0";
}

.icon-couch:before {
  content: "\e9c1";
}

.icon-credit-card:before {
  content: "\e9c2";
}

.icon-crop-alt:before {
  content: "\e9c3";
}

.icon-crop:before {
  content: "\e9c4";
}

.icon-cross:before {
  content: "\e9c5";
}

.icon-crosshairs:before {
  content: "\e9c6";
}

.icon-crow:before {
  content: "\e9c7";
}

.icon-crown:before {
  content: "\e9c8";
}

.icon-cube:before {
  content: "\e9c9";
}

.icon-cubes:before {
  content: "\e9ca";
}

.icon-cut:before {
  content: "\e9cb";
}

.icon-database:before {
  content: "\e9cc";
}

.icon-deaf:before {
  content: "\e9cd";
}

.icon-desktop:before {
  content: "\e9ce";
}

.icon-dharmachakra:before {
  content: "\e9cf";
}

.icon-diagnoses:before {
  content: "\e9d0";
}

.icon-dice-five:before {
  content: "\e9d1";
}

.icon-dice-four:before {
  content: "\e9d2";
}

.icon-dice-one:before {
  content: "\e9d3";
}

.icon-dice-six:before {
  content: "\e9d4";
}

.icon-dice-three:before {
  content: "\e9d5";
}

.icon-dice-two:before {
  content: "\e9d6";
}

.icon-dice:before {
  content: "\e9d7";
}

.icon-digital-tachograph:before {
  content: "\e9d8";
}

.icon-directions:before {
  content: "\e9d9";
}

.icon-divide:before {
  content: "\e9da";
}

.icon-dizzy:before {
  content: "\e9db";
}

.icon-dna:before {
  content: "\e9dc";
}

.icon-dollar-sign:before {
  content: "\e9dd";
}

.icon-dolly-flatbed:before {
  content: "\e9de";
}

.icon-dolly:before {
  content: "\e9df";
}

.icon-donate:before {
  content: "\e9e0";
}

.icon-door-closed:before {
  content: "\e9e1";
}

.icon-door-open:before {
  content: "\e9e2";
}

.icon-dot-circle:before {
  content: "\e9e3";
}

.icon-dove:before {
  content: "\e9e4";
}

.icon-download:before {
  content: "\e9e5";
}

.icon-drafting-compass:before {
  content: "\e9e6";
}

.icon-draw-polygon:before {
  content: "\e9e7";
}

.icon-drum-steelpan:before {
  content: "\e9e8";
}

.icon-drum:before {
  content: "\e9e9";
}

.icon-dumbbell:before {
  content: "\e9ea";
}

.icon-edit:before {
  content: "\e9eb";
}

.icon-eject:before {
  content: "\e9ec";
}

.icon-ellipsis-h:before {
  content: "\e9ed";
}

.icon-ellipsis-v:before {
  content: "\e9ee";
}

.icon-envelope-open-text:before {
  content: "\e9ef";
}

.icon-envelope-open:before {
  content: "\e9f0";
}

.icon-envelope-square:before {
  content: "\e9f1";
}

.icon-envelope:before {
  content: "\e9f2";
}

.icon-equals:before {
  content: "\e9f3";
}

.icon-eraser:before {
  content: "\e9f4";
}

.icon-euro-sign:before {
  content: "\e9f5";
}

.icon-exchange-alt:before {
  content: "\e9f6";
}

.icon-exclamation-circle:before {
  content: "\e9f7";
}

.icon-exclamation-triangle:before {
  content: "\e9f8";
}

.icon-exclamation:before {
  content: "\e9f9";
}

.icon-expand-arrows-alt:before {
  content: "\e9fa";
}

.icon-expand:before {
  content: "\e9fb";
}

.icon-external-link-alt:before {
  content: "\e9fc";
}

.icon-external-link-square-alt:before {
  content: "\e9fd";
}

.icon-eye-dropper:before {
  content: "\e9fe";
}

.icon-eye-slash:before {
  content: "\e9ff";
}

.icon-eye:before {
  content: "\ea00";
}

.icon-fast-backward:before {
  content: "\ea01";
}

.icon-fast-forward:before {
  content: "\ea02";
}

.icon-fax:before {
  content: "\ea03";
}

.icon-feather-alt:before {
  content: "\ea04";
}

.icon-feather:before {
  content: "\ea05";
}

.icon-female:before {
  content: "\ea06";
}

.icon-fighter-jet:before {
  content: "\ea07";
}

.icon-file-alt:before {
  content: "\ea08";
}

.icon-file-archive:before {
  content: "\ea09";
}

.icon-file-audio:before {
  content: "\ea0a";
}

.icon-file-code:before {
  content: "\ea0b";
}

.icon-file-contract:before {
  content: "\ea0c";
}

.icon-file-download:before {
  content: "\ea0d";
}

.icon-file-excel:before {
  content: "\ea0e";
}

.icon-file-export:before {
  content: "\ea0f";
}

.icon-file-image:before {
  content: "\ea10";
}

.icon-file-import:before {
  content: "\ea11";
}

.icon-file-invoice-dollar:before {
  content: "\ea12";
}

.icon-file-invoice:before {
  content: "\ea13";
}

.icon-file-medical-alt:before {
  content: "\ea14";
}

.icon-file-medical:before {
  content: "\ea15";
}

.icon-file-pdf:before {
  content: "\ea16";
}

.icon-file-powerpoint:before {
  content: "\ea17";
}

.icon-file-prescription:before {
  content: "\ea18";
}

.icon-file-signature:before {
  content: "\ea19";
}

.icon-file-upload:before {
  content: "\ea1a";
}

.icon-file-video:before {
  content: "\ea1b";
}

.icon-file-word:before {
  content: "\ea1c";
}

.icon-file:before {
  content: "\ea1d";
}

.icon-fill-drip:before {
  content: "\ea1e";
}

.icon-fill:before {
  content: "\ea1f";
}

.icon-film:before {
  content: "\ea20";
}

.icon-filter:before {
  content: "\ea21";
}

.icon-fingerprint:before {
  content: "\ea22";
}

.icon-fire-extinguisher:before {
  content: "\ea23";
}

.icon-fire:before {
  content: "\ea24";
}

.icon-first-aid:before {
  content: "\ea25";
}

.icon-fish:before {
  content: "\ea26";
}

.icon-flag-checkered:before {
  content: "\ea27";
}

.icon-flag:before {
  content: "\ea28";
}

.icon-flask:before {
  content: "\ea29";
}

.icon-flushed:before {
  content: "\ea2a";
}

.icon-folder-minus:before {
  content: "\ea2b";
}

.icon-folder-open:before {
  content: "\ea2c";
}

.icon-folder-plus:before {
  content: "\ea2d";
}

.icon-folder:before {
  content: "\ea2e";
}

.icon-font-awesome-logo-full:before {
  content: "\ea2f";
}

.icon-font:before {
  content: "\ea30";
}

.icon-football-ball:before {
  content: "\ea31";
}

.icon-forward:before {
  content: "\ea32";
}

.icon-frog:before {
  content: "\ea33";
}

.icon-frown-open:before {
  content: "\ea34";
}

.icon-frown:before {
  content: "\ea35";
}

.icon-funnel-dollar:before {
  content: "\ea36";
}

.icon-futbol:before {
  content: "\ea37";
}

.icon-gamepad:before {
  content: "\ea38";
}

.icon-gas-pump:before {
  content: "\ea39";
}

.icon-gavel:before {
  content: "\ea3a";
}

.icon-gem:before {
  content: "\ea3b";
}

.icon-genderless:before {
  content: "\ea3c";
}

.icon-gift:before {
  content: "\ea3d";
}

.icon-glass-martini-alt:before {
  content: "\ea3e";
}

.icon-glass-martini:before {
  content: "\ea3f";
}

.icon-glasses:before {
  content: "\ea40";
}

.icon-globe-africa:before {
  content: "\ea41";
}

.icon-globe-americas:before {
  content: "\ea42";
}

.icon-globe-asia:before {
  content: "\ea43";
}

.icon-globe:before {
  content: "\ea44";
}

.icon-golf-ball:before {
  content: "\ea45";
}

.icon-gopuram:before {
  content: "\ea46";
}

.icon-graduation-cap:before {
  content: "\ea47";
}

.icon-greater-than-equal:before {
  content: "\ea48";
}

.icon-greater-than:before {
  content: "\ea49";
}

.icon-grimace:before {
  content: "\ea4a";
}

.icon-grin-alt:before {
  content: "\ea4b";
}

.icon-grin-beam-sweat:before {
  content: "\ea4c";
}

.icon-grin-beam:before {
  content: "\ea4d";
}

.icon-grin-hearts:before {
  content: "\ea4e";
}

.icon-grin-squint-tears:before {
  content: "\ea4f";
}

.icon-grin-squint:before {
  content: "\ea50";
}

.icon-grin-stars:before {
  content: "\ea51";
}

.icon-grin-tears:before {
  content: "\ea52";
}

.icon-grin-tongue-squint:before {
  content: "\ea53";
}

.icon-grin-tongue-wink:before {
  content: "\ea54";
}

.icon-grin-tongue:before {
  content: "\ea55";
}

.icon-grin-wink:before {
  content: "\ea56";
}

.icon-grin:before {
  content: "\ea57";
}

.icon-grip-horizontal:before {
  content: "\ea58";
}

.icon-grip-vertical:before {
  content: "\ea59";
}

.icon-h-square:before {
  content: "\ea5a";
}

.icon-hamsa:before {
  content: "\ea5b";
}

.icon-hand-holding-heart:before {
  content: "\ea5c";
}

.icon-hand-holding-usd:before {
  content: "\ea5d";
}

.icon-hand-holding:before {
  content: "\ea5e";
}

.icon-hand-lizard:before {
  content: "\ea5f";
}

.icon-hand-paper:before {
  content: "\ea60";
}

.icon-hand-peace:before {
  content: "\ea61";
}

.icon-hand-point-down:before {
  content: "\ea62";
}

.icon-hand-point-left:before {
  content: "\ea63";
}

.icon-hand-point-right:before {
  content: "\ea64";
}

.icon-hand-point-up:before {
  content: "\ea65";
}

.icon-hand-pointer:before {
  content: "\ea66";
}

.icon-hand-rock:before {
  content: "\ea67";
}

.icon-hand-scissors:before {
  content: "\ea68";
}

.icon-hand-spock:before {
  content: "\ea69";
}

.icon-hands-helping:before {
  content: "\ea6a";
}

.icon-hands:before {
  content: "\ea6b";
}

.icon-handshake:before {
  content: "\ea6c";
}

.icon-hashtag:before {
  content: "\ea6d";
}

.icon-haykal:before {
  content: "\ea6e";
}

.icon-hdd:before {
  content: "\ea6f";
}

.icon-heading:before {
  content: "\ea70";
}

.icon-headphones-alt:before {
  content: "\ea71";
}

.icon-headphones:before {
  content: "\ea72";
}

.icon-headset:before {
  content: "\ea73";
}

.icon-heart:before {
  content: "\ea74";
}

.icon-heartbeat:before {
  content: "\ea75";
}

.icon-helicopter:before {
  content: "\ea76";
}

.icon-highlighter:before {
  content: "\ea77";
}

.icon-history:before {
  content: "\ea78";
}

.icon-hockey-puck:before {
  content: "\ea79";
}

.icon-home:before {
  content: "\ea7a";
}

.icon-hospital-alt:before {
  content: "\ea7b";
}

.icon-hospital-symbol:before {
  content: "\ea7c";
}

.icon-hospital:before {
  content: "\ea7d";
}

.icon-hot-tub:before {
  content: "\ea7e";
}

.icon-hotel:before {
  content: "\ea7f";
}

.icon-hourglass-end:before {
  content: "\ea80";
}

.icon-hourglass-half:before {
  content: "\ea81";
}

.icon-hourglass-start:before {
  content: "\ea82";
}

.icon-hourglass:before {
  content: "\ea83";
}

.icon-i-cursor:before {
  content: "\ea84";
}

.icon-id-badge:before {
  content: "\ea85";
}

.icon-id-card-alt:before {
  content: "\ea86";
}

.icon-id-card:before {
  content: "\ea87";
}

.icon-image:before {
  content: "\ea88";
}

.icon-images:before {
  content: "\ea89";
}

.icon-inbox:before {
  content: "\ea8a";
}

.icon-indent:before {
  content: "\ea8b";
}

.icon-industry:before {
  content: "\ea8c";
}

.icon-infinity:before {
  content: "\ea8d";
}

.icon-info-circle:before {
  content: "\ea8e";
}

.icon-info:before {
  content: "\ea8f";
}

.icon-italic:before {
  content: "\ea90";
}

.icon-jedi:before {
  content: "\ea91";
}

.icon-joint:before {
  content: "\ea92";
}

.icon-journal-whills:before {
  content: "\ea93";
}

.icon-kaaba:before {
  content: "\ea94";
}

.icon-key:before {
  content: "\ea95";
}

.icon-keyboard:before {
  content: "\ea96";
}

.icon-khanda:before {
  content: "\ea97";
}

.icon-kiss-beam:before {
  content: "\ea98";
}

.icon-kiss-wink-heart:before {
  content: "\ea99";
}

.icon-kiss:before {
  content: "\ea9a";
}

.icon-kiwi-bird:before {
  content: "\ea9b";
}

.icon-landmark:before {
  content: "\ea9c";
}

.icon-language:before {
  content: "\ea9d";
}

.icon-laptop-code:before {
  content: "\ea9e";
}

.icon-laptop:before {
  content: "\ea9f";
}

.icon-laugh-beam:before {
  content: "\eaa0";
}

.icon-laugh-squint:before {
  content: "\eaa1";
}

.icon-laugh-wink:before {
  content: "\eaa2";
}

.icon-laugh:before {
  content: "\eaa3";
}

.icon-layer-group:before {
  content: "\eaa4";
}

.icon-leaf:before {
  content: "\eaa5";
}

.icon-lemon:before {
  content: "\eaa6";
}

.icon-less-than-equal:before {
  content: "\eaa7";
}

.icon-less-than:before {
  content: "\eaa8";
}

.icon-level-down-alt:before {
  content: "\eaa9";
}

.icon-level-up-alt:before {
  content: "\eaaa";
}

.icon-life-ring:before {
  content: "\eaab";
}

.icon-lightbulb:before {
  content: "\eaac";
}

.icon-link:before {
  content: "\eaad";
}

.icon-lira-sign:before {
  content: "\eaae";
}

.icon-list-alt:before {
  content: "\eaaf";
}

.icon-list-ol:before {
  content: "\eab0";
}

.icon-list-ul:before {
  content: "\eab1";
}

.icon-list:before {
  content: "\eab2";
}

.icon-location-arrow:before {
  content: "\eab3";
}

.icon-lock-open:before {
  content: "\eab4";
}

.icon-lock:before {
  content: "\eab5";
}

.icon-long-arrow-alt-down:before {
  content: "\eab6";
}

.icon-long-arrow-alt-left:before {
  content: "\eab7";
}

.icon-long-arrow-alt-right:before {
  content: "\eab8";
}

.icon-long-arrow-alt-up:before {
  content: "\eab9";
}

.icon-low-vision:before {
  content: "\eaba";
}

.icon-luggage-cart:before {
  content: "\eabb";
}

.icon-magic:before {
  content: "\eabc";
}

.icon-magnet:before {
  content: "\eabd";
}

.icon-mail-bulk:before {
  content: "\eabe";
}

.icon-male:before {
  content: "\eabf";
}

.icon-map-marked-alt:before {
  content: "\eac0";
}

.icon-map-marked:before {
  content: "\eac1";
}

.icon-map-marker-alt:before {
  content: "\eac2";
}

.icon-map-marker:before {
  content: "\eac3";
}

.icon-map-pin:before {
  content: "\eac4";
}

.icon-map-signs:before {
  content: "\eac5";
}

.icon-map:before {
  content: "\eac6";
}

.icon-marker:before {
  content: "\eac7";
}

.icon-mars-double:before {
  content: "\eac8";
}

.icon-mars-stroke-h:before {
  content: "\eac9";
}

.icon-mars-stroke-v:before {
  content: "\eaca";
}

.icon-mars-stroke:before {
  content: "\eacb";
}

.icon-mars:before {
  content: "\eacc";
}

.icon-medal:before {
  content: "\eacd";
}

.icon-medkit:before {
  content: "\eace";
}

.icon-meh-blank:before {
  content: "\eacf";
}

.icon-meh-rolling-eyes:before {
  content: "\ead0";
}

.icon-meh:before {
  content: "\ead1";
}

.icon-memory:before {
  content: "\ead2";
}

.icon-menorah:before {
  content: "\ead3";
}

.icon-mercury:before {
  content: "\ead4";
}

.icon-microchip:before {
  content: "\ead5";
}

.icon-microphone-alt-slash:before {
  content: "\ead6";
}

.icon-microphone-alt:before {
  content: "\ead7";
}

.icon-microphone-slash:before {
  content: "\ead8";
}

.icon-microphone:before {
  content: "\ead9";
}

.icon-microscope:before {
  content: "\eada";
}

.icon-minus-circle:before {
  content: "\eadb";
}

.icon-minus-square:before {
  content: "\eadc";
}

.icon-minus:before {
  content: "\eadd";
}

.icon-mobile-alt:before {
  content: "\eade";
}

.icon-mobile:before {
  content: "\eadf";
}

.icon-money-bill-alt:before {
  content: "\eae0";
}

.icon-money-bill-wave-alt:before {
  content: "\eae1";
}

.icon-money-bill-wave:before {
  content: "\eae2";
}

.icon-money-bill:before {
  content: "\eae3";
}

.icon-money-check-alt:before {
  content: "\eae4";
}

.icon-money-check:before {
  content: "\eae5";
}

.icon-monument:before {
  content: "\eae6";
}

.icon-moon:before {
  content: "\eae7";
}

.icon-mortar-pestle:before {
  content: "\eae8";
}

.icon-mosque:before {
  content: "\eae9";
}

.icon-motorcycle:before {
  content: "\eaea";
}

.icon-mouse-pointer:before {
  content: "\eaeb";
}

.icon-music:before {
  content: "\eaec";
}

.icon-neuter:before {
  content: "\eaed";
}

.icon-newspaper:before {
  content: "\eaee";
}

.icon-not-equal:before {
  content: "\eaef";
}

.icon-notes-medical:before {
  content: "\eaf0";
}

.icon-object-group:before {
  content: "\eaf1";
}

.icon-object-ungroup:before {
  content: "\eaf2";
}

.icon-oil-can:before {
  content: "\eaf3";
}

.icon-om:before {
  content: "\eaf4";
}

.icon-outdent:before {
  content: "\eaf5";
}

.icon-paint-brush:before {
  content: "\eaf6";
}

.icon-paint-roller:before {
  content: "\eaf7";
}

.icon-palette:before {
  content: "\eaf8";
}

.icon-pallet:before {
  content: "\eaf9";
}

.icon-paper-plane:before {
  content: "\eafa";
}

.icon-paperclip:before {
  content: "\eafb";
}

.icon-parachute-box:before {
  content: "\eafc";
}

.icon-paragraph:before {
  content: "\eafd";
}

.icon-parking:before {
  content: "\eafe";
}

.icon-passport:before {
  content: "\eaff";
}

.icon-pastafarianism:before {
  content: "\eb00";
}

.icon-paste:before {
  content: "\eb01";
}

.icon-pause-circle:before {
  content: "\eb02";
}

.icon-pause:before {
  content: "\eb03";
}

.icon-paw:before {
  content: "\eb04";
}

.icon-peace:before {
  content: "\eb05";
}

.icon-pen-alt:before {
  content: "\eb06";
}

.icon-pen-fancy:before {
  content: "\eb07";
}

.icon-pen-nib:before {
  content: "\eb08";
}

.icon-pen-square:before {
  content: "\eb09";
}

.icon-pen:before {
  content: "\eb0a";
}

.icon-pencil-alt:before {
  content: "\eb0b";
}

.icon-pencil-ruler:before {
  content: "\eb0c";
}

.icon-people-carry:before {
  content: "\eb0d";
}

.icon-percent:before {
  content: "\eb0e";
}

.icon-percentage:before {
  content: "\eb0f";
}

.icon-phone-slash:before {
  content: "\eb10";
}

.icon-phone-square:before {
  content: "\eb11";
}

.icon-phone-volume:before {
  content: "\eb12";
}

.icon-phone:before {
  content: "\eb13";
}

.icon-piggy-bank:before {
  content: "\eb14";
}

.icon-pills:before {
  content: "\eb15";
}

.icon-place-of-worship:before {
  content: "\eb16";
}

.icon-plane-arrival:before {
  content: "\eb17";
}

.icon-plane-departure:before {
  content: "\eb18";
}

.icon-plane:before {
  content: "\eb19";
}

.icon-play-circle:before {
  content: "\eb1a";
}

.icon-play:before {
  content: "\eb1b";
}

.icon-plug:before {
  content: "\eb1c";
}

.icon-plus-circle:before {
  content: "\eb1d";
}

.icon-plus-square:before {
  content: "\eb1e";
}

.icon-plus:before {
  content: "\eb1f";
}

.icon-podcast:before {
  content: "\eb20";
}

.icon-poll-h:before {
  content: "\eb21";
}

.icon-poll:before {
  content: "\eb22";
}

.icon-poo:before {
  content: "\eb23";
}

.icon-poop:before {
  content: "\eb24";
}

.icon-portrait:before {
  content: "\eb25";
}

.icon-pound-sign:before {
  content: "\eb26";
}

.icon-power-off:before {
  content: "\eb27";
}

.icon-pray:before {
  content: "\eb28";
}

.icon-praying-hands:before {
  content: "\eb29";
}

.icon-prescription-bottle-alt:before {
  content: "\eb2a";
}

.icon-prescription-bottle:before {
  content: "\eb2b";
}

.icon-prescription:before {
  content: "\eb2c";
}

.icon-print:before {
  content: "\eb2d";
}

.icon-procedures:before {
  content: "\eb2e";
}

.icon-project-diagram:before {
  content: "\eb2f";
}

.icon-puzzle-piece:before {
  content: "\eb30";
}

.icon-qrcode:before {
  content: "\eb31";
}

.icon-question-circle:before {
  content: "\eb32";
}

.icon-question:before {
  content: "\eb33";
}

.icon-quidditch:before {
  content: "\eb34";
}

.icon-quote-left:before {
  content: "\eb35";
}

.icon-quote-right:before {
  content: "\eb36";
}

.icon-quran:before {
  content: "\eb37";
}

.icon-random:before {
  content: "\eb38";
}

.icon-receipt:before {
  content: "\eb39";
}

.icon-recycle:before {
  content: "\eb3a";
}

.icon-redo-alt:before {
  content: "\eb3b";
}

.icon-redo:before {
  content: "\eb3c";
}

.icon-registered:before {
  content: "\eb3d";
}

.icon-reply-all:before {
  content: "\eb3e";
}

.icon-reply:before {
  content: "\eb3f";
}

.icon-retweet:before {
  content: "\eb40";
}

.icon-ribbon:before {
  content: "\eb41";
}

.icon-road:before {
  content: "\eb42";
}

.icon-robot:before {
  content: "\eb43";
}

.icon-rocket:before {
  content: "\eb44";
}

.icon-route:before {
  content: "\eb45";
}

.icon-rss-square:before {
  content: "\eb46";
}

.icon-rss:before {
  content: "\eb47";
}

.icon-ruble-sign:before {
  content: "\eb48";
}

.icon-ruler-combined:before {
  content: "\eb49";
}

.icon-ruler-horizontal:before {
  content: "\eb4a";
}

.icon-ruler-vertical:before {
  content: "\eb4b";
}

.icon-ruler:before {
  content: "\eb4c";
}

.icon-rupee-sign:before {
  content: "\eb4d";
}

.icon-sad-cry:before {
  content: "\eb4e";
}

.icon-sad-tear:before {
  content: "\eb4f";
}

.icon-save:before {
  content: "\eb50";
}

.icon-school:before {
  content: "\eb51";
}

.icon-screwdriver:before {
  content: "\eb52";
}

.icon-search-dollar:before {
  content: "\eb53";
}

.icon-search-location:before {
  content: "\eb54";
}

.icon-search-minus:before {
  content: "\eb55";
}

.icon-search-plus:before {
  content: "\eb56";
}

.icon-search:before {
  content: "\eb57";
}

.icon-seedling:before {
  content: "\eb58";
}

.icon-server:before {
  content: "\eb59";
}

.icon-shapes:before {
  content: "\eb5a";
}

.icon-share-alt-square:before {
  content: "\eb5b";
}

.icon-share-alt:before {
  content: "\eb5c";
}

.icon-share-square:before {
  content: "\eb5d";
}

.icon-share:before {
  content: "\eb5e";
}

.icon-shekel-sign:before {
  content: "\eb5f";
}

.icon-shield-alt:before {
  content: "\eb60";
}

.icon-ship:before {
  content: "\eb61";
}

.icon-shipping-fast:before {
  content: "\eb62";
}

.icon-shoe-prints:before {
  content: "\eb63";
}

.icon-shopping-bag:before {
  content: "\eb64";
}

.icon-shopping-basket:before {
  content: "\eb65";
}

.icon-shopping-cart1:before {
  content: "\eb66";
}

.icon-shower:before {
  content: "\eb67";
}

.icon-shuttle-van:before {
  content: "\eb68";
}

.icon-sign-in-alt:before {
  content: "\eb69";
}

.icon-sign-language:before {
  content: "\eb6a";
}

.icon-sign-out-alt:before {
  content: "\eb6b";
}

.icon-sign:before {
  content: "\eb6c";
}

.icon-signal:before {
  content: "\eb6d";
}

.icon-signature:before {
  content: "\eb6e";
}

.icon-sitemap:before {
  content: "\eb6f";
}

.icon-skull:before {
  content: "\eb70";
}

.icon-sliders-h:before {
  content: "\eb71";
}

.icon-smile-beam:before {
  content: "\eb72";
}

.icon-smile-wink:before {
  content: "\eb73";
}

.icon-smile:before {
  content: "\eb74";
}

.icon-smoking-ban:before {
  content: "\eb75";
}

.icon-smoking:before {
  content: "\eb76";
}

.icon-snowflake:before {
  content: "\eb77";
}

.icon-socks:before {
  content: "\eb78";
}

.icon-solar-panel:before {
  content: "\eb79";
}

.icon-sort-alpha-down:before {
  content: "\eb7a";
}

.icon-sort-alpha-up:before {
  content: "\eb7b";
}

.icon-sort-amount-down:before {
  content: "\eb7c";
}

.icon-sort-amount-up:before {
  content: "\eb7d";
}

.icon-sort-down:before {
  content: "\eb7e";
}

.icon-sort-numeric-down:before {
  content: "\eb7f";
}

.icon-sort-numeric-up:before {
  content: "\eb80";
}

.icon-sort-up:before {
  content: "\eb81";
}

.icon-sort:before {
  content: "\eb82";
}

.icon-spa:before {
  content: "\eb83";
}

.icon-space-shuttle:before {
  content: "\eb84";
}

.icon-spinner:before {
  content: "\eb85";
}

.icon-splotch:before {
  content: "\eb86";
}

.icon-spray-can:before {
  content: "\eb87";
}

.icon-square-full:before {
  content: "\eb88";
}

.icon-square-root-alt:before {
  content: "\eb89";
}

.icon-square:before {
  content: "\eb8a";
}

.icon-stamp:before {
  content: "\eb8b";
}

.icon-star-and-crescent:before {
  content: "\eb8c";
}

.icon-star-half-alt:before {
  content: "\eb8d";
}

.icon-star-half:before {
  content: "\eb8e";
}

.icon-star-of-david:before {
  content: "\eb8f";
}

.icon-star-of-life:before {
  content: "\eb90";
}

.icon-star:before {
  content: "\eb91";
}

.icon-step-backward:before {
  content: "\eb92";
}

.icon-step-forward:before {
  content: "\eb93";
}

.icon-stethoscope:before {
  content: "\eb94";
}

.icon-sticky-note:before {
  content: "\eb95";
}

.icon-stop-circle:before {
  content: "\eb96";
}

.icon-stop:before {
  content: "\eb97";
}

.icon-stopwatch:before {
  content: "\eb98";
}

.icon-store-alt:before {
  content: "\eb99";
}

.icon-store:before {
  content: "\eb9a";
}

.icon-stream:before {
  content: "\eb9b";
}

.icon-street-view:before {
  content: "\eb9c";
}

.icon-strikethrough:before {
  content: "\eb9d";
}

.icon-stroopwafel:before {
  content: "\eb9e";
}

.icon-subscript:before {
  content: "\eb9f";
}

.icon-subway:before {
  content: "\eba0";
}

.icon-suitcase-rolling:before {
  content: "\eba1";
}

.icon-suitcase:before {
  content: "\eba2";
}

.icon-sun:before {
  content: "\eba3";
}

.icon-superscript:before {
  content: "\eba4";
}

.icon-surprise:before {
  content: "\eba5";
}

.icon-swatchbook:before {
  content: "\eba6";
}

.icon-swimmer:before {
  content: "\eba7";
}

.icon-swimming-pool:before {
  content: "\eba8";
}

.icon-synagogue:before {
  content: "\eba9";
}

.icon-sync-alt:before {
  content: "\ebaa";
}

.icon-sync:before {
  content: "\ebab";
}

.icon-syringe:before {
  content: "\ebac";
}

.icon-table-tennis:before {
  content: "\ebad";
}

.icon-table:before {
  content: "\ebae";
}

.icon-tablet-alt:before {
  content: "\ebaf";
}

.icon-tablet:before {
  content: "\ebb0";
}

.icon-tablets:before {
  content: "\ebb1";
}

.icon-tachometer-alt:before {
  content: "\ebb2";
}

.icon-tag:before {
  content: "\ebb3";
}

.icon-tags:before {
  content: "\ebb4";
}

.icon-tape:before {
  content: "\ebb5";
}

.icon-tasks:before {
  content: "\ebb6";
}

.icon-taxi:before {
  content: "\ebb7";
}

.icon-teeth-open:before {
  content: "\ebb8";
}

.icon-teeth:before {
  content: "\ebb9";
}

.icon-terminal:before {
  content: "\ebba";
}

.icon-text-height:before {
  content: "\ebbb";
}

.icon-text-width:before {
  content: "\ebbc";
}

.icon-th-large:before {
  content: "\ebbd";
}

.icon-th-list:before {
  content: "\ebbe";
}

.icon-th:before {
  content: "\ebbf";
}

.icon-theater-masks:before {
  content: "\ebc0";
}

.icon-thermometer-empty:before {
  content: "\ebc1";
}

.icon-thermometer-full:before {
  content: "\ebc2";
}

.icon-thermometer-half:before {
  content: "\ebc3";
}

.icon-thermometer-quarter:before {
  content: "\ebc4";
}

.icon-thermometer-three-quarters:before {
  content: "\ebc5";
}

.icon-thermometer:before {
  content: "\ebc6";
}

.icon-thumbs-down:before {
  content: "\ebc7";
}

.icon-thumbs-up:before {
  content: "\ebc8";
}

.icon-thumbtack:before {
  content: "\ebc9";
}

.icon-ticket-alt:before {
  content: "\ebca";
}

.icon-times-circle:before {
  content: "\ebcb";
}

.icon-times:before {
  content: "\ebcc";
}

.icon-tint-slash:before {
  content: "\ebcd";
}

.icon-tint:before {
  content: "\ebce";
}

.icon-tired:before {
  content: "\ebcf";
}

.icon-toggle-off:before {
  content: "\ebd0";
}

.icon-toggle-on:before {
  content: "\ebd1";
}

.icon-toolbox:before {
  content: "\ebd2";
}

.icon-tooth:before {
  content: "\ebd3";
}

.icon-torah:before {
  content: "\ebd4";
}

.icon-torii-gate:before {
  content: "\ebd5";
}

.icon-trademark:before {
  content: "\ebd6";
}

.icon-traffic-light:before {
  content: "\ebd7";
}

.icon-train:before {
  content: "\ebd8";
}

.icon-transgender-alt:before {
  content: "\ebd9";
}

.icon-transgender:before {
  content: "\ebda";
}

.icon-trash-alt:before {
  content: "\ebdb";
}

.icon-trash:before {
  content: "\ebdc";
}

.icon-tree:before {
  content: "\ebdd";
}

.icon-trophy:before {
  content: "\ebde";
}

.icon-truck-loading:before {
  content: "\ebdf";
}

.icon-truck-monster:before {
  content: "\ebe0";
}

.icon-truck-moving:before {
  content: "\ebe1";
}

.icon-truck-pickup:before {
  content: "\ebe2";
}

.icon-truck:before {
  content: "\ebe3";
}

.icon-tshirt:before {
  content: "\ebe4";
}

.icon-tty:before {
  content: "\ebe5";
}

.icon-tv:before {
  content: "\ebe6";
}

.icon-umbrella-beach:before {
  content: "\ebe7";
}

.icon-umbrella:before {
  content: "\ebe8";
}

.icon-underline:before {
  content: "\ebe9";
}

.icon-undo-alt:before {
  content: "\ebea";
}

.icon-undo:before {
  content: "\ebeb";
}

.icon-universal-access:before {
  content: "\ebec";
}

.icon-university:before {
  content: "\ebed";
}

.icon-unlink:before {
  content: "\ebee";
}

.icon-unlock-alt:before {
  content: "\ebef";
}

.icon-unlock:before {
  content: "\ebf0";
}

.icon-upload:before {
  content: "\ebf1";
}

.icon-user-alt-slash:before {
  content: "\ebf2";
}

.icon-user-alt:before {
  content: "\ebf3";
}

.icon-user-astronaut:before {
  content: "\ebf4";
}

.icon-user-check:before {
  content: "\ebf5";
}

.icon-user-circle:before {
  content: "\ebf6";
}

.icon-user-clock:before {
  content: "\ebf7";
}

.icon-user-cog:before {
  content: "\ebf8";
}

.icon-user-edit:before {
  content: "\ebf9";
}

.icon-user-friends:before {
  content: "\ebfa";
}

.icon-user-graduate:before {
  content: "\ebfb";
}

.icon-user-lock:before {
  content: "\ebfc";
}

.icon-user-md:before {
  content: "\ebfd";
}

.icon-user-minus:before {
  content: "\ebfe";
}

.icon-user-ninja:before {
  content: "\ebff";
}

.icon-user-plus:before {
  content: "\ec00";
}

.icon-user-secret:before {
  content: "\ec01";
}

.icon-user-shield:before {
  content: "\ec02";
}

.icon-user-slash:before {
  content: "\ec03";
}

.icon-user-tag:before {
  content: "\ec04";
}

.icon-user-tie:before {
  content: "\ec05";
}

.icon-user-times:before {
  content: "\ec06";
}

.icon-user:before {
  content: "\ec07";
}

.icon-users-cog:before {
  content: "\ec08";
}

.icon-users:before {
  content: "\ec09";
}

.icon-utensil-spoon:before {
  content: "\ec0a";
}

.icon-utensils:before {
  content: "\ec0b";
}

.icon-vector-square:before {
  content: "\ec0c";
}

.icon-venus-double:before {
  content: "\ec0d";
}

.icon-venus-mars:before {
  content: "\ec0e";
}

.icon-venus:before {
  content: "\ec0f";
}

.icon-vial:before {
  content: "\ec10";
}

.icon-vials:before {
  content: "\ec11";
}

.icon-video-slash:before {
  content: "\ec12";
}

.icon-video:before {
  content: "\ec13";
}

.icon-vihara:before {
  content: "\ec14";
}

.icon-volleyball-ball:before {
  content: "\ec15";
}

.icon-volume-down:before {
  content: "\ec16";
}

.icon-volume-off:before {
  content: "\ec17";
}

.icon-volume-up:before {
  content: "\ec18";
}

.icon-walking:before {
  content: "\ec19";
}

.icon-wallet:before {
  content: "\ec1a";
}

.icon-warehouse:before {
  content: "\ec1b";
}

.icon-weight-hanging:before {
  content: "\ec1c";
}

.icon-weight:before {
  content: "\ec1d";
}

.icon-wheelchair:before {
  content: "\ec1e";
}

.icon-wifi:before {
  content: "\ec1f";
}

.icon-window-close:before {
  content: "\ec20";
}

.icon-window-maximize:before {
  content: "\ec21";
}

.icon-window-minimize:before {
  content: "\ec22";
}

.icon-window-restore:before {
  content: "\ec23";
}

.icon-wine-glass-alt:before {
  content: "\ec24";
}

.icon-wine-glass:before {
  content: "\ec25";
}

.icon-won-sign:before {
  content: "\ec26";
}

.icon-wrench:before {
  content: "\ec27";
}

.icon-x-ray:before {
  content: "\ec28";
}

.icon-yen-sign:before {
  content: "\ec29";
}

.icon-yin-yang:before {
  content: "\ec2a";
}

.icon-address-book1:before {
  content: "\ec2b";
}

.icon-address-card1:before {
  content: "\ec2c";
}

.icon-angry1:before {
  content: "\ec2d";
}

.icon-arrow-alt-circle-down1:before {
  content: "\ec2e";
}

.icon-arrow-alt-circle-left1:before {
  content: "\ec2f";
}

.icon-arrow-alt-circle-right1:before {
  content: "\ec30";
}

.icon-arrow-alt-circle-up1:before {
  content: "\ec31";
}

.icon-bell-slash1:before {
  content: "\ec32";
}

.icon-bell1:before {
  content: "\ec33";
}

.icon-bookmark1:before {
  content: "\ec34";
}

.icon-building1:before {
  content: "\ec35";
}

.icon-calendar-alt1:before {
  content: "\ec36";
}

.icon-calendar-check1:before {
  content: "\ec37";
}

.icon-calendar-minus1:before {
  content: "\ec38";
}

.icon-calendar-plus1:before {
  content: "\ec39";
}

.icon-calendar-times1:before {
  content: "\ec3a";
}

.icon-calendar1:before {
  content: "\ec3b";
}

.icon-caret-square-down1:before {
  content: "\ec3c";
}

.icon-caret-square-left1:before {
  content: "\ec3d";
}

.icon-caret-square-right1:before {
  content: "\ec3e";
}

.icon-caret-square-up1:before {
  content: "\ec3f";
}

.icon-chart-bar1:before {
  content: "\ec40";
}

.icon-check-circle1:before {
  content: "\ec41";
}

.icon-check-square1:before {
  content: "\ec42";
}

.icon-circle1:before {
  content: "\ec43";
}

.icon-clipboard1:before {
  content: "\ec44";
}

.icon-clock1:before {
  content: "\ec45";
}

.icon-clone1:before {
  content: "\ec46";
}

.icon-closed-captioning1:before {
  content: "\ec47";
}

.icon-comment-alt1:before {
  content: "\ec48";
}

.icon-comment-dots1:before {
  content: "\ec49";
}

.icon-comment1:before {
  content: "\ec4a";
}

.icon-comments1:before {
  content: "\ec4b";
}

.icon-compass1:before {
  content: "\ec4c";
}

.icon-copy1:before {
  content: "\ec4d";
}

.icon-copyright1:before {
  content: "\ec4e";
}

.icon-credit-card1:before {
  content: "\ec4f";
}

.icon-dizzy1:before {
  content: "\ec50";
}

.icon-dot-circle1:before {
  content: "\ec51";
}

.icon-edit1:before {
  content: "\ec52";
}

.icon-envelope-open1:before {
  content: "\ec53";
}

.icon-envelope1:before {
  content: "\ec54";
}

.icon-eye-slash1:before {
  content: "\ec55";
}

.icon-eye1:before {
  content: "\ec56";
}

.icon-file-alt1:before {
  content: "\ec57";
}

.icon-file-archive1:before {
  content: "\ec58";
}

.icon-file-audio1:before {
  content: "\ec59";
}

.icon-file-code1:before {
  content: "\ec5a";
}

.icon-file-excel1:before {
  content: "\ec5b";
}

.icon-file-image1:before {
  content: "\ec5c";
}

.icon-file-pdf1:before {
  content: "\ec5d";
}

.icon-file-powerpoint1:before {
  content: "\ec5e";
}

.icon-file-video1:before {
  content: "\ec5f";
}

.icon-file-word1:before {
  content: "\ec60";
}

.icon-file1:before {
  content: "\ec61";
}

.icon-flag1:before {
  content: "\ec62";
}

.icon-flushed1:before {
  content: "\ec63";
}

.icon-folder-open1:before {
  content: "\ec64";
}

.icon-folder1:before {
  content: "\ec65";
}

.icon-font-awesome-logo-full1:before {
  content: "\ec66";
}

.icon-frown-open1:before {
  content: "\ec67";
}

.icon-frown1:before {
  content: "\ec68";
}

.icon-futbol1:before {
  content: "\ec69";
}

.icon-gem1:before {
  content: "\ec6a";
}

.icon-grimace1:before {
  content: "\ec6b";
}

.icon-grin-alt1:before {
  content: "\ec6c";
}

.icon-grin-beam-sweat1:before {
  content: "\ec6d";
}

.icon-grin-beam1:before {
  content: "\ec6e";
}

.icon-grin-hearts1:before {
  content: "\ec6f";
}

.icon-grin-squint-tears1:before {
  content: "\ec70";
}

.icon-grin-squint1:before {
  content: "\ec71";
}

.icon-grin-stars1:before {
  content: "\ec72";
}

.icon-grin-tears1:before {
  content: "\ec73";
}

.icon-grin-tongue-squint1:before {
  content: "\ec74";
}

.icon-grin-tongue-wink1:before {
  content: "\ec75";
}

.icon-grin-tongue1:before {
  content: "\ec76";
}

.icon-grin-wink1:before {
  content: "\ec77";
}

.icon-grin1:before {
  content: "\ec78";
}

.icon-hand-lizard1:before {
  content: "\ec79";
}

.icon-hand-paper1:before {
  content: "\ec7a";
}

.icon-hand-peace1:before {
  content: "\ec7b";
}

.icon-hand-point-down1:before {
  content: "\ec7c";
}

.icon-hand-point-left1:before {
  content: "\ec7d";
}

.icon-hand-point-right1:before {
  content: "\ec7e";
}

.icon-hand-point-up1:before {
  content: "\ec7f";
}

.icon-hand-pointer1:before {
  content: "\ec80";
}

.icon-hand-rock1:before {
  content: "\ec81";
}

.icon-hand-scissors1:before {
  content: "\ec82";
}

.icon-hand-spock1:before {
  content: "\ec83";
}

.icon-handshake1:before {
  content: "\ec84";
}

.icon-hdd1:before {
  content: "\ec85";
}

.icon-heart1:before {
  content: "\ec86";
}

.icon-hospital1:before {
  content: "\ec87";
}

.icon-hourglass1:before {
  content: "\ec88";
}

.icon-id-badge1:before {
  content: "\ec89";
}

.icon-id-card1:before {
  content: "\ec8a";
}

.icon-image1:before {
  content: "\ec8b";
}

.icon-images1:before {
  content: "\ec8c";
}

.icon-keyboard1:before {
  content: "\ec8d";
}

.icon-kiss-beam1:before {
  content: "\ec8e";
}

.icon-kiss-wink-heart1:before {
  content: "\ec8f";
}

.icon-kiss1:before {
  content: "\ec90";
}

.icon-laugh-beam1:before {
  content: "\ec91";
}

.icon-laugh-squint1:before {
  content: "\ec92";
}

.icon-laugh-wink1:before {
  content: "\ec93";
}

.icon-laugh1:before {
  content: "\ec94";
}

.icon-lemon1:before {
  content: "\ec95";
}

.icon-life-ring1:before {
  content: "\ec96";
}

.icon-lightbulb1:before {
  content: "\ec97";
}

.icon-list-alt1:before {
  content: "\ec98";
}

.icon-map1:before {
  content: "\ec99";
}

.icon-meh-blank1:before {
  content: "\ec9a";
}

.icon-meh-rolling-eyes1:before {
  content: "\ec9b";
}

.icon-meh1:before {
  content: "\ec9c";
}

.icon-minus-square1:before {
  content: "\ec9d";
}

.icon-money-bill-alt1:before {
  content: "\ec9e";
}

.icon-moon1:before {
  content: "\ec9f";
}

.icon-newspaper1:before {
  content: "\eca0";
}

.icon-object-group1:before {
  content: "\eca1";
}

.icon-object-ungroup1:before {
  content: "\eca2";
}

.icon-paper-plane1:before {
  content: "\eca3";
}

.icon-pause-circle1:before {
  content: "\eca4";
}

.icon-play-circle1:before {
  content: "\eca5";
}

.icon-plus-square1:before {
  content: "\eca6";
}

.icon-question-circle1:before {
  content: "\eca7";
}

.icon-registered1:before {
  content: "\eca8";
}

.icon-sad-cry1:before {
  content: "\eca9";
}

.icon-sad-tear1:before {
  content: "\ecaa";
}

.icon-save1:before {
  content: "\ecab";
}

.icon-share-square1:before {
  content: "\ecac";
}

.icon-smile-beam1:before {
  content: "\ecad";
}

.icon-smile-wink1:before {
  content: "\ecae";
}

.icon-smile1:before {
  content: "\ecaf";
}

.icon-snowflake1:before {
  content: "\ecb0";
}

.icon-square1:before {
  content: "\ecb1";
}

.icon-star-half1:before {
  content: "\ecb2";
}

.icon-star1:before {
  content: "\ecb3";
}

.icon-sticky-note1:before {
  content: "\ecb4";
}

.icon-stop-circle1:before {
  content: "\ecb5";
}

.icon-sun1:before {
  content: "\ecb6";
}

.icon-surprise1:before {
  content: "\ecb7";
}

.icon-thumbs-down1:before {
  content: "\ecb8";
}

.icon-thumbs-up1:before {
  content: "\ecb9";
}

.icon-times-circle1:before {
  content: "\ecba";
}

.icon-tired1:before {
  content: "\ecbb";
}

.icon-trash-alt1:before {
  content: "\ecbc";
}

.icon-user-circle1:before {
  content: "\ecbd";
}

.icon-user1:before {
  content: "\ecbe";
}

.icon-window-close1:before {
  content: "\ecbf";
}

.icon-window-maximize1:before {
  content: "\ecc0";
}

.icon-window-minimize1:before {
  content: "\ecc1";
}

.icon-window-restore1:before {
  content: "\ecc2";
}

.icon-px:before {
  content: "\ecc3";
}

.icon-accessible-icon:before {
  content: "\ecc4";
}

.icon-accusoft:before {
  content: "\ecc5";
}

.icon-adn:before {
  content: "\ecc6";
}

.icon-adversal:before {
  content: "\ecc7";
}

.icon-affiliatetheme:before {
  content: "\ecc8";
}

.icon-algolia:before {
  content: "\ecc9";
}

.icon-alipay:before {
  content: "\ecca";
}

.icon-amazon-pay:before {
  content: "\eccb";
}

.icon-amazon:before {
  content: "\eccc";
}

.icon-amilia:before {
  content: "\eccd";
}

.icon-android:before {
  content: "\ecce";
}

.icon-angellist:before {
  content: "\eccf";
}

.icon-angrycreative:before {
  content: "\ecd0";
}

.icon-angular:before {
  content: "\ecd1";
}

.icon-app-store-ios:before {
  content: "\ecd2";
}

.icon-app-store:before {
  content: "\ecd3";
}

.icon-apper:before {
  content: "\ecd4";
}

.icon-apple-pay:before {
  content: "\ecd5";
}

.icon-apple:before {
  content: "\ecd6";
}

.icon-asymmetrik:before {
  content: "\ecd7";
}

.icon-audible:before {
  content: "\ecd8";
}

.icon-autoprefixer:before {
  content: "\ecd9";
}

.icon-avianex:before {
  content: "\ecda";
}

.icon-aviato:before {
  content: "\ecdb";
}

.icon-aws:before {
  content: "\ecdc";
}

.icon-bandcamp:before {
  content: "\ecdd";
}

.icon-behance-square:before {
  content: "\ecde";
}

.icon-behance:before {
  content: "\ecdf";
}

.icon-bimobject:before {
  content: "\ece0";
}

.icon-bitbucket:before {
  content: "\ece1";
}

.icon-bitcoin:before {
  content: "\ece2";
}

.icon-bity:before {
  content: "\ece3";
}

.icon-black-tie:before {
  content: "\ece4";
}

.icon-blackberry:before {
  content: "\ece5";
}

.icon-blogger-b:before {
  content: "\ece6";
}

.icon-blogger:before {
  content: "\ece7";
}

.icon-bluetooth-b:before {
  content: "\ece8";
}

.icon-bluetooth:before {
  content: "\ece9";
}

.icon-btc:before {
  content: "\ecea";
}

.icon-buromobelexperte:before {
  content: "\eceb";
}

.icon-buysellads:before {
  content: "\ecec";
}

.icon-cc-amazon-pay:before {
  content: "\eced";
}

.icon-cc-amex:before {
  content: "\ecee";
}

.icon-cc-apple-pay:before {
  content: "\ecef";
}

.icon-cc-diners-club:before {
  content: "\ecf0";
}

.icon-cc-discover:before {
  content: "\ecf1";
}

.icon-cc-jcb:before {
  content: "\ecf2";
}

.icon-cc-mastercard:before {
  content: "\ecf3";
}

.icon-cc-paypal:before {
  content: "\ecf4";
}

.icon-cc-stripe:before {
  content: "\ecf5";
}

.icon-cc-visa:before {
  content: "\ecf6";
}

.icon-centercode:before {
  content: "\ecf7";
}

.icon-chrome:before {
  content: "\ecf8";
}

.icon-cloudscale:before {
  content: "\ecf9";
}

.icon-cloudsmith:before {
  content: "\ecfa";
}

.icon-cloudversify:before {
  content: "\ecfb";
}

.icon-codepen:before {
  content: "\ecfc";
}

.icon-codiepie:before {
  content: "\ecfd";
}

.icon-connectdevelop:before {
  content: "\ecfe";
}

.icon-contao:before {
  content: "\ecff";
}

.icon-cpanel:before {
  content: "\ed00";
}

.icon-creative-commons-by:before {
  content: "\ed01";
}

.icon-creative-commons-nc-eu:before {
  content: "\ed02";
}

.icon-creative-commons-nc-jp:before {
  content: "\ed03";
}

.icon-creative-commons-nc:before {
  content: "\ed04";
}

.icon-creative-commons-nd:before {
  content: "\ed05";
}

.icon-creative-commons-pd-alt:before {
  content: "\ed06";
}

.icon-creative-commons-pd:before {
  content: "\ed07";
}

.icon-creative-commons-remix:before {
  content: "\ed08";
}

.icon-creative-commons-sa:before {
  content: "\ed09";
}

.icon-creative-commons-sampling-plus:before {
  content: "\ed0a";
}

.icon-creative-commons-sampling:before {
  content: "\ed0b";
}

.icon-creative-commons-share:before {
  content: "\ed0c";
}

.icon-creative-commons:before {
  content: "\ed0d";
}

.icon-css3-alt:before {
  content: "\ed0e";
}

.icon-css3:before {
  content: "\ed0f";
}

.icon-cuttlefish:before {
  content: "\ed10";
}

.icon-d-and-d:before {
  content: "\ed11";
}

.icon-dashcube:before {
  content: "\ed12";
}

.icon-delicious:before {
  content: "\ed13";
}

.icon-deploydog:before {
  content: "\ed14";
}

.icon-deskpro:before {
  content: "\ed15";
}

.icon-deviantart:before {
  content: "\ed16";
}

.icon-digg:before {
  content: "\ed17";
}

.icon-digital-ocean:before {
  content: "\ed18";
}

.icon-discord:before {
  content: "\ed19";
}

.icon-discourse:before {
  content: "\ed1a";
}

.icon-dochub:before {
  content: "\ed1b";
}

.icon-docker:before {
  content: "\ed1c";
}

.icon-draft2digital:before {
  content: "\ed1d";
}

.icon-dribbble-square:before {
  content: "\ed1e";
}

.icon-dribbble:before {
  content: "\ed1f";
}

.icon-dropbox:before {
  content: "\ed20";
}

.icon-drupal:before {
  content: "\ed21";
}

.icon-dyalog:before {
  content: "\ed22";
}

.icon-earlybirds:before {
  content: "\ed23";
}

.icon-ebay:before {
  content: "\ed24";
}

.icon-edge:before {
  content: "\ed25";
}

.icon-elementor:before {
  content: "\ed26";
}

.icon-ello:before {
  content: "\ed27";
}

.icon-ember:before {
  content: "\ed28";
}

.icon-empire:before {
  content: "\ed29";
}

.icon-envira:before {
  content: "\ed2a";
}

.icon-erlang:before {
  content: "\ed2b";
}

.icon-ethereum:before {
  content: "\ed2c";
}

.icon-etsy:before {
  content: "\ed2d";
}

.icon-expeditedssl:before {
  content: "\ed2e";
}

.icon-facebook-f:before {
  content: "\ed2f";
}

.icon-facebook-messenger:before {
  content: "\ed30";
}

.icon-facebook-square:before {
  content: "\ed31";
}

.icon-facebook1:before {
  content: "\ed32";
}

.icon-firefox:before {
  content: "\ed33";
}

.icon-first-order-alt:before {
  content: "\ed34";
}

.icon-first-order:before {
  content: "\ed35";
}

.icon-firstdraft:before {
  content: "\ed36";
}

.icon-flickr:before {
  content: "\ed37";
}

.icon-flipboard:before {
  content: "\ed38";
}

.icon-fly:before {
  content: "\ed39";
}

.icon-font-awesome-alt:before {
  content: "\ed3a";
}

.icon-font-awesome-flag:before {
  content: "\ed3b";
}

.icon-font-awesome-logo-full2:before {
  content: "\ed3c";
}

.icon-font-awesome:before {
  content: "\ed3d";
}

.icon-fonticons-fi:before {
  content: "\ed3e";
}

.icon-fonticons:before {
  content: "\ed3f";
}

.icon-fort-awesome-alt:before {
  content: "\ed40";
}

.icon-fort-awesome:before {
  content: "\ed41";
}

.icon-forumbee:before {
  content: "\ed42";
}

.icon-foursquare:before {
  content: "\ed43";
}

.icon-free-code-camp:before {
  content: "\ed44";
}

.icon-freebsd:before {
  content: "\ed45";
}

.icon-fulcrum:before {
  content: "\ed46";
}

.icon-galactic-republic:before {
  content: "\ed47";
}

.icon-galactic-senate:before {
  content: "\ed48";
}

.icon-get-pocket:before {
  content: "\ed49";
}

.icon-gg-circle:before {
  content: "\ed4a";
}

.icon-gg:before {
  content: "\ed4b";
}

.icon-git-square:before {
  content: "\ed4c";
}

.icon-git:before {
  content: "\ed4d";
}

.icon-github-alt:before {
  content: "\ed4e";
}

.icon-github-square:before {
  content: "\ed4f";
}

.icon-github:before {
  content: "\ed50";
}

.icon-gitkraken:before {
  content: "\ed51";
}

.icon-gitlab:before {
  content: "\ed52";
}

.icon-gitter:before {
  content: "\ed53";
}

.icon-glide-g:before {
  content: "\ed54";
}

.icon-glide:before {
  content: "\ed55";
}

.icon-gofore:before {
  content: "\ed56";
}

.icon-goodreads-g:before {
  content: "\ed57";
}

.icon-goodreads:before {
  content: "\ed58";
}

.icon-google-drive:before {
  content: "\ed59";
}

.icon-google-play:before {
  content: "\ed5a";
}

.icon-google-plus-g:before {
  content: "\ed5b";
}

.icon-google-plus-square:before {
  content: "\ed5c";
}

.icon-google-plus:before {
  content: "\ed5d";
}

.icon-google-wallet:before {
  content: "\ed5e";
}

.icon-google:before {
  content: "\ed5f";
}

.icon-gratipay:before {
  content: "\ed60";
}

.icon-grav:before {
  content: "\ed61";
}

.icon-gripfire:before {
  content: "\ed62";
}

.icon-grunt:before {
  content: "\ed63";
}

.icon-gulp:before {
  content: "\ed64";
}

.icon-hacker-news-square:before {
  content: "\ed65";
}

.icon-hacker-news:before {
  content: "\ed66";
}

.icon-hackerrank:before {
  content: "\ed67";
}

.icon-hips:before {
  content: "\ed68";
}

.icon-hire-a-helper:before {
  content: "\ed69";
}

.icon-hooli:before {
  content: "\ed6a";
}

.icon-hornbill:before {
  content: "\ed6b";
}

.icon-hotjar:before {
  content: "\ed6c";
}

.icon-houzz:before {
  content: "\ed6d";
}

.icon-html5:before {
  content: "\ed6e";
}

.icon-hubspot:before {
  content: "\ed6f";
}

.icon-imdb:before {
  content: "\ed70";
}

.icon-instagram:before {
  content: "\ed71";
}

.icon-internet-explorer:before {
  content: "\ed72";
}

.icon-ioxhost:before {
  content: "\ed73";
}

.icon-itunes-note:before {
  content: "\ed74";
}

.icon-itunes:before {
  content: "\ed75";
}

.icon-java:before {
  content: "\ed76";
}

.icon-jedi-order:before {
  content: "\ed77";
}

.icon-jenkins:before {
  content: "\ed78";
}

.icon-joget:before {
  content: "\ed79";
}

.icon-joomla:before {
  content: "\ed7a";
}

.icon-js-square:before {
  content: "\ed7b";
}

.icon-js:before {
  content: "\ed7c";
}

.icon-jsfiddle:before {
  content: "\ed7d";
}

.icon-kaggle:before {
  content: "\ed7e";
}

.icon-keybase:before {
  content: "\ed7f";
}

.icon-keycdn:before {
  content: "\ed80";
}

.icon-kickstarter-k:before {
  content: "\ed81";
}

.icon-kickstarter:before {
  content: "\ed82";
}

.icon-korvue:before {
  content: "\ed83";
}

.icon-laravel:before {
  content: "\ed84";
}

.icon-lastfm-square:before {
  content: "\ed85";
}

.icon-lastfm:before {
  content: "\ed86";
}

.icon-leanpub:before {
  content: "\ed87";
}

.icon-less:before {
  content: "\ed88";
}

.icon-line:before {
  content: "\ed89";
}

.icon-linkedin-in:before {
  content: "\ed8a";
}

.icon-linkedin:before {
  content: "\ed8b";
}

.icon-linode:before {
  content: "\ed8c";
}

.icon-linux:before {
  content: "\ed8d";
}

.icon-lyft:before {
  content: "\ed8e";
}

.icon-magento:before {
  content: "\ed8f";
}

.icon-mailchimp:before {
  content: "\ed90";
}

.icon-mandalorian:before {
  content: "\ed91";
}

.icon-markdown:before {
  content: "\ed92";
}

.icon-mastodon:before {
  content: "\ed93";
}

.icon-maxcdn:before {
  content: "\ed94";
}

.icon-medapps:before {
  content: "\ed95";
}

.icon-medium-m:before {
  content: "\ed96";
}

.icon-medium:before {
  content: "\ed97";
}

.icon-medrt:before {
  content: "\ed98";
}

.icon-meetup:before {
  content: "\ed99";
}

.icon-megaport:before {
  content: "\ed9a";
}

.icon-microsoft:before {
  content: "\ed9b";
}

.icon-mix:before {
  content: "\ed9c";
}

.icon-mixcloud:before {
  content: "\ed9d";
}

.icon-mizuni:before {
  content: "\ed9e";
}

.icon-modx:before {
  content: "\ed9f";
}

.icon-monero:before {
  content: "\eda0";
}

.icon-napster:before {
  content: "\eda1";
}

.icon-neos:before {
  content: "\eda2";
}

.icon-nimblr:before {
  content: "\eda3";
}

.icon-nintendo-switch:before {
  content: "\eda4";
}

.icon-node-js:before {
  content: "\eda5";
}

.icon-node:before {
  content: "\eda6";
}

.icon-npm:before {
  content: "\eda7";
}

.icon-ns8:before {
  content: "\eda8";
}

.icon-nutritionix:before {
  content: "\eda9";
}

.icon-odnoklassniki-square:before {
  content: "\edaa";
}

.icon-odnoklassniki:before {
  content: "\edab";
}

.icon-old-republic:before {
  content: "\edac";
}

.icon-opencart:before {
  content: "\edad";
}

.icon-openid:before {
  content: "\edae";
}

.icon-opera:before {
  content: "\edaf";
}

.icon-optin-monster:before {
  content: "\edb0";
}

.icon-osi:before {
  content: "\edb1";
}

.icon-page4:before {
  content: "\edb2";
}

.icon-pagelines:before {
  content: "\edb3";
}

.icon-palfed:before {
  content: "\edb4";
}

.icon-patreon:before {
  content: "\edb5";
}

.icon-paypal:before {
  content: "\edb6";
}

.icon-periscope:before {
  content: "\edb7";
}

.icon-phabricator:before {
  content: "\edb8";
}

.icon-phoenix-framework:before {
  content: "\edb9";
}

.icon-phoenix-squadron:before {
  content: "\edba";
}

.icon-php:before {
  content: "\edbb";
}

.icon-pied-piper-alt:before {
  content: "\edbc";
}

.icon-pied-piper-hat:before {
  content: "\edbd";
}

.icon-pied-piper-pp:before {
  content: "\edbe";
}

.icon-pied-piper:before {
  content: "\edbf";
}

.icon-pinterest-p:before {
  content: "\edc0";
}

.icon-pinterest-square:before {
  content: "\edc1";
}

.icon-pinterest:before {
  content: "\edc2";
}

.icon-playstation:before {
  content: "\edc3";
}

.icon-product-hunt:before {
  content: "\edc4";
}

.icon-pushed:before {
  content: "\edc5";
}

.icon-python:before {
  content: "\edc6";
}

.icon-qq:before {
  content: "\edc7";
}

.icon-quinscape:before {
  content: "\edc8";
}

.icon-quora:before {
  content: "\edc9";
}

.icon-r-project:before {
  content: "\edca";
}

.icon-ravelry:before {
  content: "\edcb";
}

.icon-react:before {
  content: "\edcc";
}

.icon-readme:before {
  content: "\edcd";
}

.icon-rebel:before {
  content: "\edce";
}

.icon-red-river:before {
  content: "\edcf";
}

.icon-reddit-alien:before {
  content: "\edd0";
}

.icon-reddit-square:before {
  content: "\edd1";
}

.icon-reddit:before {
  content: "\edd2";
}

.icon-rendact:before {
  content: "\edd3";
}

.icon-renren:before {
  content: "\edd4";
}

.icon-replyd:before {
  content: "\edd5";
}

.icon-researchgate:before {
  content: "\edd6";
}

.icon-resolving:before {
  content: "\edd7";
}

.icon-rev:before {
  content: "\edd8";
}

.icon-rocketchat:before {
  content: "\edd9";
}

.icon-rockrms:before {
  content: "\edda";
}

.icon-safari:before {
  content: "\eddb";
}

.icon-sass:before {
  content: "\eddc";
}

.icon-schlix:before {
  content: "\eddd";
}

.icon-scribd:before {
  content: "\edde";
}

.icon-searchengin:before {
  content: "\eddf";
}

.icon-sellcast:before {
  content: "\ede0";
}

.icon-sellsy:before {
  content: "\ede1";
}

.icon-servicestack:before {
  content: "\ede2";
}

.icon-shirtsinbulk:before {
  content: "\ede3";
}

.icon-shopware:before {
  content: "\ede4";
}

.icon-simplybuilt:before {
  content: "\ede5";
}

.icon-sistrix:before {
  content: "\ede6";
}

.icon-sith:before {
  content: "\ede7";
}

.icon-skyatlas:before {
  content: "\ede8";
}

.icon-skype:before {
  content: "\ede9";
}

.icon-slack-hash:before {
  content: "\edea";
}

.icon-slack:before {
  content: "\edeb";
}

.icon-slideshare:before {
  content: "\edec";
}

.icon-snapchat-ghost:before {
  content: "\eded";
}

.icon-snapchat-square:before {
  content: "\edee";
}

.icon-snapchat:before {
  content: "\edef";
}

.icon-soundcloud:before {
  content: "\edf0";
}

.icon-speakap:before {
  content: "\edf1";
}

.icon-spotify:before {
  content: "\edf2";
}

.icon-squarespace:before {
  content: "\edf3";
}

.icon-stack-exchange:before {
  content: "\edf4";
}

.icon-stack-overflow:before {
  content: "\edf5";
}

.icon-staylinked:before {
  content: "\edf6";
}

.icon-steam-square:before {
  content: "\edf7";
}

.icon-steam-symbol:before {
  content: "\edf8";
}

.icon-steam:before {
  content: "\edf9";
}

.icon-sticker-mule:before {
  content: "\edfa";
}

.icon-strava:before {
  content: "\edfb";
}

.icon-stripe-s:before {
  content: "\edfc";
}

.icon-stripe:before {
  content: "\edfd";
}

.icon-studiovinari:before {
  content: "\edfe";
}

.icon-stumbleupon-circle:before {
  content: "\edff";
}

.icon-stumbleupon:before {
  content: "\ee00";
}

.icon-superpowers:before {
  content: "\ee01";
}

.icon-supple:before {
  content: "\ee02";
}

.icon-teamspeak:before {
  content: "\ee03";
}

.icon-telegram-plane:before {
  content: "\ee04";
}

.icon-telegram:before {
  content: "\ee05";
}

.icon-tencent-weibo:before {
  content: "\ee06";
}

.icon-the-red-yeti:before {
  content: "\ee07";
}

.icon-themeco:before {
  content: "\ee08";
}

.icon-themeisle:before {
  content: "\ee09";
}

.icon-trade-federation:before {
  content: "\ee0a";
}

.icon-trello:before {
  content: "\ee0b";
}

.icon-tripadvisor:before {
  content: "\ee0c";
}

.icon-tumblr-square:before {
  content: "\ee0d";
}

.icon-tumblr:before {
  content: "\ee0e";
}

.icon-twitch:before {
  content: "\ee0f";
}

.icon-twitter-square:before {
  content: "\ee10";
}

.icon-twitter:before {
  content: "\ee11";
}

.icon-typo3:before {
  content: "\ee12";
}

.icon-uber:before {
  content: "\ee13";
}

.icon-uikit:before {
  content: "\ee14";
}

.icon-uniregistry:before {
  content: "\ee15";
}

.icon-untappd:before {
  content: "\ee16";
}

.icon-usb:before {
  content: "\ee17";
}

.icon-ussunnah:before {
  content: "\ee18";
}

.icon-vaadin:before {
  content: "\ee19";
}

.icon-viacoin:before {
  content: "\ee1a";
}

.icon-viadeo-square:before {
  content: "\ee1b";
}

.icon-viadeo:before {
  content: "\ee1c";
}

.icon-viber:before {
  content: "\ee1d";
}

.icon-vimeo-square:before {
  content: "\ee1e";
}

.icon-vimeo-v:before {
  content: "\ee1f";
}

.icon-vimeo:before {
  content: "\ee20";
}

.icon-vine:before {
  content: "\ee21";
}

.icon-vk:before {
  content: "\ee22";
}

.icon-vnv:before {
  content: "\ee23";
}

.icon-vuejs:before {
  content: "\ee24";
}

.icon-weebly:before {
  content: "\ee25";
}

.icon-weibo:before {
  content: "\ee26";
}

.icon-weixin:before {
  content: "\ee27";
}

.icon-whatsapp-square:before {
  content: "\ee28";
}

.icon-whatsapp:before {
  content: "\ee29";
}

.icon-whmcs:before {
  content: "\ee2a";
}

.icon-wikipedia-w:before {
  content: "\ee2b";
}

.icon-windows:before {
  content: "\ee2c";
}

.icon-wix:before {
  content: "\ee2d";
}

.icon-wolf-pack-battalion:before {
  content: "\ee2e";
}

.icon-wordpress-simple:before {
  content: "\ee2f";
}

.icon-wordpress:before {
  content: "\ee30";
}

.icon-wpbeginner:before {
  content: "\ee31";
}

.icon-wpexplorer:before {
  content: "\ee32";
}

.icon-wpforms:before {
  content: "\ee33";
}

.icon-xbox:before {
  content: "\ee34";
}

.icon-xing-square:before {
  content: "\ee35";
}

.icon-xing:before {
  content: "\ee36";
}

.icon-y-combinator:before {
  content: "\ee37";
}

.icon-yahoo:before {
  content: "\ee38";
}

.icon-yandex-international:before {
  content: "\ee39";
}

.icon-yandex:before {
  content: "\ee3a";
}

.icon-yelp:before {
  content: "\ee3b";
}

.icon-yoast:before {
  content: "\ee3c";
}

.icon-youtube-square:before {
  content: "\ee3d";
}

.icon-youtube:before {
  content: "\ee3e";
}

.icon-zhihu:before {
  content: "\ee3f";
}

.icon-line-open:before {
  content: "\ea21";
}

.icon-line-bag:before {
  content: "\ea23";
}

.icon-line-grid-2:before {
  content: "\ea24";
}

.icon-line-content-left:before {
  content: "\ea25";
}

.icon-line-content-right:before {
  content: "\ea26";
}

.icon-line-esc:before {
  content: "\ea27";
}

.icon-line-alt:before {
  content: "\ea28";
}

.icon-line-marquee-plus:before {
  content: "\ea29";
}

.icon-line-marquee-minus:before {
  content: "\ea2a";
}

.icon-line-marquee:before {
  content: "\ea2b";
}

.icon-line-square-check:before {
  content: "\ea2d";
}

.icon-line-paragraph:before {
  content: "\ea2e";
}

.icon-line-ribbon:before {
  content: "\ea2f";
}

.icon-line-location-2:before {
  content: "\ea1c";
}

.icon-line-circle-check:before {
  content: "\ea1d";
}

.icon-line-circle-cross1:before {
  content: "\ea1e";
}

.icon-line-reply:before {
  content: "\ea1f";
}

.icon-line-paper-stack:before {
  content: "\ea20";
}

.icon-line-stack-2:before {
  content: "\ea1a";
}

.icon-line-stack:before {
  content: "\ea1b";
}

.icon-line-activity:before {
  content: "\e900";
}

.icon-line-air-play:before {
  content: "\e901";
}

.icon-line-alert-circle:before {
  content: "\e902";
}

.icon-line-alert-octagon:before {
  content: "\e903";
}

.icon-line-alert-triangle:before {
  content: "\e904";
}

.icon-line-align-center:before {
  content: "\e905";
}

.icon-line-align-justify:before {
  content: "\e906";
}

.icon-line-align-left:before {
  content: "\e907";
}

.icon-line-align-right:before {
  content: "\e908";
}

.icon-line-anchor:before {
  content: "\e909";
}

.icon-line-aperture:before {
  content: "\e90a";
}

.icon-line-archive:before {
  content: "\e90b";
}

.icon-line-arrow-down:before {
  content: "\e90c";
}

.icon-line-arrow-down-circle:before {
  content: "\e90d";
}

.icon-line-arrow-down-left:before {
  content: "\e90e";
}

.icon-line-arrow-down-right:before {
  content: "\e90f";
}

.icon-line-arrow-left:before {
  content: "\e910";
}

.icon-line-arrow-left-circle:before {
  content: "\e911";
}

.icon-line-arrow-right:before {
  content: "\e912";
}

.icon-line-arrow-right-circle:before {
  content: "\e913";
}

.icon-line-arrow-up:before {
  content: "\e914";
}

.icon-line-arrow-up-circle:before {
  content: "\e915";
}

.icon-line-arrow-up-left:before {
  content: "\e916";
}

.icon-line-arrow-up-right:before {
  content: "\e917";
}

.icon-line-at-sign:before {
  content: "\e918";
}

.icon-line-award:before {
  content: "\e919";
}

.icon-line-bar-graph:before {
  content: "\e91a";
}

.icon-line-bar-graph-2:before {
  content: "\e91b";
}

.icon-line-battery:before {
  content: "\e91c";
}

.icon-line-battery-charging:before {
  content: "\e91d";
}

.icon-line-bell:before {
  content: "\e91e";
}

.icon-line-bell-off:before {
  content: "\e91f";
}

.icon-line-bluetooth:before {
  content: "\e920";
}

.icon-line-bold:before {
  content: "\e921";
}

.icon-line-book:before {
  content: "\e922";
}

.icon-line-book-open:before {
  content: "\e923";
}

.icon-line-bookmark:before {
  content: "\e924";
}

.icon-line-box:before {
  content: "\e925";
}

.icon-line-briefcase:before {
  content: "\e926";
}

.icon-line-calendar:before {
  content: "\e927";
}

.icon-line-camera:before {
  content: "\e928";
}

.icon-line-camera-off:before {
  content: "\e929";
}

.icon-line-cast:before {
  content: "\e92a";
}

.icon-line-check:before {
  content: "\e92b";
}

.icon-line-check-circle:before {
  content: "\e92c";
}

.icon-line-check-square:before {
  content: "\e92d";
}

.icon-line-chevron-down:before {
  content: "\e92e";
}

.icon-line-chevron-left:before {
  content: "\e92f";
}

.icon-line-chevron-right:before {
  content: "\e930";
}

.icon-line-chevron-up:before {
  content: "\e931";
}

.icon-line-chevrons-down:before {
  content: "\e932";
}

.icon-line-chevrons-left:before {
  content: "\e933";
}

.icon-line-chevrons-right:before {
  content: "\e934";
}

.icon-line-chevrons-up:before {
  content: "\e935";
}

.icon-line-chrome:before {
  content: "\e936";
}

.icon-line-record:before,
.icon-line-stop:before {
  content: "\e937";
}

.icon-line-clipboard:before {
  content: "\e938";
}

.icon-line-clock:before {
  content: "\e939";
}

.icon-line-cloud:before {
  content: "\e93a";
}

.icon-line-cloud-drizzle:before {
  content: "\e93b";
}

.icon-line-cloud-lightning:before {
  content: "\e93c";
}

.icon-line-cloud-off:before {
  content: "\e93d";
}

.icon-line-cloud-rain:before {
  content: "\e93e";
}

.icon-line-cloud-snow:before {
  content: "\e93f";
}

.icon-line-code:before {
  content: "\e940";
}

.icon-line-codepen:before {
  content: "\e941";
}

.icon-line-codesandbox:before {
  content: "\e942";
}

.icon-line-coffee:before {
  content: "\e943";
}

.icon-line-columns:before {
  content: "\e944";
}

.icon-line-command:before {
  content: "\e945";
}

.icon-line-compass:before {
  content: "\e946";
}

.icon-line-copy:before {
  content: "\e947";
}

.icon-line-corner-down-left:before {
  content: "\e948";
}

.icon-line-corner-down-right:before {
  content: "\e949";
}

.icon-line-corner-left-down:before {
  content: "\e94a";
}

.icon-line-corner-left-up:before {
  content: "\e94b";
}

.icon-line-corner-right-down:before {
  content: "\e94c";
}

.icon-line-corner-right-up:before {
  content: "\e94d";
}

.icon-line-corner-up-left:before {
  content: "\e94e";
}

.icon-line-corner-up-right:before {
  content: "\e94f";
}

.icon-line-cpu:before {
  content: "\e950";
}

.icon-line-credit-card:before {
  content: "\e951";
}

.icon-line-crop:before {
  content: "\e952";
}

.icon-line-crosshair:before {
  content: "\e953";
}

.icon-line-database:before {
  content: "\e954";
}

.icon-line-delete:before {
  content: "\e955";
}

.icon-line-disc:before {
  content: "\e956";
}

.icon-line-dollar-sign:before {
  content: "\e957";
}

.icon-line-download:before {
  content: "\e958";
}

.icon-line-cloud-download:before {
  content: "\e959";
}

.icon-line-droplet:before {
  content: "\e95a";
}

.icon-line-edit:before {
  content: "\e95b";
}

.icon-line-edit-2:before {
  content: "\e95c";
}

.icon-line-edit-3:before {
  content: "\e95d";
}

.icon-line-external-link:before {
  content: "\e95e";
}

.icon-line-eye:before {
  content: "\e95f";
}

.icon-line-eye-off:before {
  content: "\e960";
}

.icon-line-facebook:before {
  content: "\e961";
}

.icon-line-fast-forward:before {
  content: "\e962";
}

.icon-line-feather:before {
  content: "\e963";
}

.icon-line-figma:before {
  content: "\e964";
}

.icon-line-file:before {
  content: "\e965";
}

.icon-line-file-subtract:before {
  content: "\e966";
}

.icon-line-file-add:before {
  content: "\e967";
}

.icon-line-paper:before {
  content: "\e968";
}

.icon-line-film:before {
  content: "\e969";
}

.icon-line-filter:before {
  content: "\e96a";
}

.icon-line-flag:before {
  content: "\e96b";
}

.icon-line-folder:before {
  content: "\e96c";
}

.icon-line-folder-minus:before {
  content: "\e96d";
}

.icon-line-folder-plus:before {
  content: "\e96e";
}

.icon-line-framer:before {
  content: "\e96f";
}

.icon-line-frown:before {
  content: "\e970";
}

.icon-line-gift:before {
  content: "\e971";
}

.icon-line-git-branch:before {
  content: "\e972";
}

.icon-line-git-commit:before {
  content: "\e973";
}

.icon-line-git-merge:before {
  content: "\e974";
}

.icon-line-git-pull-request:before {
  content: "\e975";
}

.icon-line-github:before {
  content: "\e976";
}

.icon-line-gitlab:before {
  content: "\e977";
}

.icon-line-globe:before {
  content: "\e978";
}

.icon-line-grid:before {
  content: "\e979";
}

.icon-line-hard-drive:before {
  content: "\e97a";
}

.icon-line-hash:before {
  content: "\e97b";
}

.icon-line-headphones:before {
  content: "\e97c";
}

.icon-line-heart:before {
  content: "\e97d";
}

.icon-line-help-circle:before {
  content: "\e97e";
}

.icon-line-hexagon:before {
  content: "\e97f";
}

.icon-line-home:before {
  content: "\e980";
}

.icon-line-image:before {
  content: "\e981";
}

.icon-line-inbox:before {
  content: "\e982";
}

.icon-line-info:before {
  content: "\e983";
}

.icon-line-instagram:before {
  content: "\e984";
}

.icon-line-italic:before {
  content: "\e985";
}

.icon-line-key:before {
  content: "\e986";
}

.icon-line-layers:before {
  content: "\e987";
}

.icon-line-layout:before {
  content: "\e988";
}

.icon-line-help:before {
  content: "\e989";
}

.icon-line-link:before {
  content: "\e98a";
}

.icon-line-link-2:before {
  content: "\e98b";
}

.icon-line-linkedin:before {
  content: "\e98c";
}

.icon-line-list:before {
  content: "\e98d";
}

.icon-line-loader:before {
  content: "\e98e";
}

.icon-line-lock:before {
  content: "\e98f";
}

.icon-line-log-in:before {
  content: "\e990";
}

.icon-line-log-out:before {
  content: "\e991";
}

.icon-line-mail:before {
  content: "\e992";
}

.icon-line-map:before {
  content: "\e993";
}

.icon-line-map-pin:before {
  content: "\e994";
}

.icon-line-expand:before {
  content: "\e995";
}

.icon-line-maximize:before {
  content: "\e996";
}

.icon-line-meh:before {
  content: "\e997";
}

.icon-line-menu:before {
  content: "\e998";
}

.icon-line-message-circle:before {
  content: "\e999";
}

.icon-line-speech-bubble:before {
  content: "\e99a";
}

.icon-line-microphone:before {
  content: "\e99b";
}

.icon-line-microphone-off:before {
  content: "\e99c";
}

.icon-line-contract:before {
  content: "\e99d";
}

.icon-line-minimize:before {
  content: "\e99e";
}

.icon-line-minus:before {
  content: "\e99f";
}

.icon-line-circle-minus:before {
  content: "\e9a0";
}

.icon-line-square-minus:before {
  content: "\e9a1";
}

.icon-line-monitor:before {
  content: "\e9a2";
}

.icon-line-moon:before {
  content: "\e9a3";
}

.icon-line-more-horizontal:before,
.icon-line-ellipsis:before {
  content: "\e9a4";
}

.icon-line-more-vertical:before {
  content: "\e9a5";
}

.icon-line-mouse-pointer:before {
  content: "\e9a6";
}

.icon-line-move:before {
  content: "\e9a7";
}

.icon-line-music:before {
  content: "\e9a8";
}

.icon-line-location:before {
  content: "\e9a9";
}

.icon-line-navigation:before {
  content: "\e9aa";
}

.icon-line-octagon:before {
  content: "\e9ab";
}

.icon-line-package:before {
  content: "\e9ac";
}

.icon-line-paper-clip:before {
  content: "\e9ad";
}

.icon-line-pause:before {
  content: "\e9ae";
}

.icon-line-pause-circle:before {
  content: "\e9af";
}

.icon-line-pen-tool:before {
  content: "\e9b0";
}

.icon-line-percent:before {
  content: "\e9b1";
}

.icon-line-phone:before {
  content: "\e9b2";
}

.icon-line-phone-call:before {
  content: "\e9b3";
}

.icon-line-phone-forwarded:before {
  content: "\e9b4";
}

.icon-line-phone-incoming:before {
  content: "\e9b5";
}

.icon-line-phone-missed:before {
  content: "\e9b6";
}

.icon-line-phone-off:before {
  content: "\e9b7";
}

.icon-line-phone-outgoing:before {
  content: "\e9b8";
}

.icon-line-pie-graph:before {
  content: "\e9b9";
}

.icon-line-play:before {
  content: "\e9ba";
}

.icon-line-play-circle:before {
  content: "\e9bb";
}

.icon-line-plus:before {
  content: "\e9bc";
}

.icon-line-circle-plus:before {
  content: "\e9bd";
}

.icon-line-square-plus:before {
  content: "\e9be";
}

.icon-line-pocket:before {
  content: "\e9bf";
}

.icon-line-power:before {
  content: "\e9c0";
}

.icon-line-printer:before {
  content: "\e9c1";
}

.icon-line-signal:before {
  content: "\e9c2";
}

.icon-line-refresh-ccw:before {
  content: "\e9c3";
}

.icon-line-refresh-cw:before {
  content: "\e9c4";
}

.icon-line-repeat:before {
  content: "\e9c5";
}

.icon-line-rewind:before {
  content: "\e9c6";
}

.icon-line-reload:before {
  content: "\e9c7";
}

.icon-line-rotate-cw:before {
  content: "\e9c8";
}

.icon-line-rss:before {
  content: "\e9c9";
}

.icon-line-save:before {
  content: "\e9ca";
}

.icon-line-scissors:before {
  content: "\e9cb";
}

.icon-line-search:before {
  content: "\e9cc";
}

.icon-line-send:before {
  content: "\e9cd";
}

.icon-line-server:before {
  content: "\e9ce";
}

.icon-line-cog:before {
  content: "\e9cf";
}

.icon-line-outbox:before {
  content: "\e9d0";
}

.icon-line-share:before {
  content: "\e9d1";
}

.icon-line-shield:before {
  content: "\e9d2";
}

.icon-line-shield-off:before {
  content: "\e9d3";
}

.icon-line-shopping-bag:before {
  content: "\e9d4";
}

.icon-line-shopping-cart:before {
  content: "\e9d5";
}

.icon-line-shuffle:before {
  content: "\e9d6";
}

.icon-line-sidebar:before {
  content: "\e9d7";
}

.icon-line-skip-back:before {
  content: "\e9d8";
}

.icon-line-skip-forward:before {
  content: "\e9d9";
}

.icon-line-slack:before {
  content: "\e9da";
}

.icon-line-ban:before {
  content: "\e9db";
}

.icon-line-sliders:before {
  content: "\e9dc";
}

.icon-line-smartphone:before {
  content: "\e9dd";
}

.icon-line-smile:before {
  content: "\e9de";
}

.icon-line-speaker:before {
  content: "\e9df";
}

.icon-line-square:before {
  content: "\e9e0";
}

.icon-line-star:before {
  content: "\e9e1";
}

.icon-line-stop-circle:before {
  content: "\e9e2";
}

.icon-line-sun:before {
  content: "\e9e3";
}

.icon-line-sunrise:before {
  content: "\e9e4";
}

.icon-line-sunset:before {
  content: "\e9e5";
}

.icon-line-tablet:before {
  content: "\e9e6";
}

.icon-line-tag:before {
  content: "\e9e7";
}

.icon-line-target:before {
  content: "\e9e8";
}

.icon-line-terminal:before {
  content: "\e9e9";
}

.icon-line-thermometer:before {
  content: "\e9ea";
}

.icon-line-thumbs-down:before {
  content: "\e9eb";
}

.icon-line-thumbs-up:before {
  content: "\e9ec";
}

.icon-line-toggle:before {
  content: "\e9ed";
}

.icon-line-toggle-right:before {
  content: "\e9ee";
}

.icon-line-tool:before {
  content: "\e9ef";
}

.icon-line-trash:before {
  content: "\e9f0";
}

.icon-line-trash-2:before {
  content: "\e9f1";
}

.icon-line-trello:before {
  content: "\e9f2";
}

.icon-line-trending-down:before {
  content: "\e9f3";
}

.icon-line-trending-up:before {
  content: "\e9f4";
}

.icon-line-triangle:before {
  content: "\e9f5";
}

.icon-line-truck:before {
  content: "\e9f6";
}

.icon-line-tv:before {
  content: "\e9f7";
}

.icon-line-twitch:before {
  content: "\e9f8";
}

.icon-line-twitter:before {
  content: "\e9f9";
}

.icon-line-type:before {
  content: "\e9fa";
}

.icon-line-umbrella:before {
  content: "\e9fb";
}

.icon-line-underline:before {
  content: "\e9fc";
}

.icon-line-unlock:before {
  content: "\e9fd";
}

.icon-line-upload:before {
  content: "\e9fe";
}

.icon-line-cloud-upload:before {
  content: "\e9ff";
}

.icon-line-head:before {
  content: "\ea00";
}

.icon-line-user-check:before {
  content: "\ea01";
}

.icon-line-user-minus:before {
  content: "\ea02";
}

.icon-line-user-plus:before {
  content: "\ea03";
}

.icon-line-user-cross:before {
  content: "\ea04";
}

.icon-line-users:before {
  content: "\ea05";
}

.icon-line-video:before {
  content: "\ea06";
}

.icon-line-video-off:before {
  content: "\ea07";
}

.icon-line-voicemail:before {
  content: "\ea08";
}

.icon-line-volume-off:before {
  content: "\ea09";
}

.icon-line-volume-1:before {
  content: "\ea0a";
}

.icon-line-volume:before {
  content: "\ea0b";
}

.icon-line-mute:before {
  content: "\ea0c";
}

.icon-line-watch:before {
  content: "\ea0d";
}

.icon-line-wifi:before {
  content: "\ea0e";
}

.icon-line-wifi-off:before {
  content: "\ea0f";
}

.icon-line-wind:before {
  content: "\ea10";
}

.icon-line-cross:before {
  content: "\ea11";
}

.icon-line-circle-cross:before {
  content: "\ea12";
}

.icon-line-cross-octagon:before {
  content: "\ea13";
}

.icon-line-square-cross:before {
  content: "\ea14";
}

.icon-line-youtube:before {
  content: "\ea15";
}

.icon-line-zap:before {
  content: "\ea16";
}

.icon-line-zap-off:before {
  content: "\ea17";
}

.icon-line-zoom-in:before {
  content: "\ea18";
}

.icon-line-zoom-out:before {
  content: "\ea19";
}

.icon-line2-user-female:before {
  content: "\e000";
}

.icon-line2-user-follow:before {
  content: "\e002";
}

.icon-line2-user-following:before {
  content: "\e003";
}

.icon-line2-user-unfollow:before {
  content: "\e004";
}

.icon-line2-trophy:before {
  content: "\e006";
}

.icon-line2-screen-smartphone:before {
  content: "\e010";
}

.icon-line2-screen-desktop:before {
  content: "\e011";
}

.icon-line2-plane:before {
  content: "\e012";
}

.icon-line2-notebook:before {
  content: "\e013";
}

.icon-line2-moustache:before {
  content: "\e014";
}

.icon-line2-mouse:before {
  content: "\e015";
}

.icon-line2-magnet:before {
  content: "\e016";
}

.icon-line2-energy:before {
  content: "\e020";
}

.icon-line2-emoticon-smile:before {
  content: "\e021";
}

.icon-line2-disc:before {
  content: "\e022";
}

.icon-line2-cursor-move:before {
  content: "\e023";
}

.icon-line2-crop:before {
  content: "\e024";
}

.icon-line2-credit-card:before {
  content: "\e025";
}

.icon-line2-chemistry:before {
  content: "\e026";
}

.icon-line2-user:before {
  content: "\e005";
}

.icon-line2-speedometer:before {
  content: "\e007";
}

.icon-line2-social-youtube:before {
  content: "\e008";
}

.icon-line2-social-twitter:before {
  content: "\e009";
}

.icon-line2-social-tumblr:before {
  content: "\e00a";
}

.icon-line2-social-facebook:before {
  content: "\e00b";
}

.icon-line2-social-dropbox:before {
  content: "\e00c";
}

.icon-line2-social-dribbble:before {
  content: "\e00d";
}

.icon-line2-shield:before {
  content: "\e00e";
}

.icon-line2-screen-tablet:before {
  content: "\e00f";
}

.icon-line2-magic-wand:before {
  content: "\e017";
}

.icon-line2-hourglass:before {
  content: "\e018";
}

.icon-line2-graduation:before {
  content: "\e019";
}

.icon-line2-ghost:before {
  content: "\e01a";
}

.icon-line2-game-controller:before {
  content: "\e01b";
}

.icon-line2-fire:before {
  content: "\e01c";
}

.icon-line2-eyeglasses:before {
  content: "\e01d";
}

.icon-line2-envelope-open:before {
  content: "\e01e";
}

.icon-line2-envelope-letter:before {
  content: "\e01f";
}

.icon-line2-bell:before {
  content: "\e027";
}

.icon-line2-badge:before {
  content: "\e028";
}

.icon-line2-anchor:before {
  content: "\e029";
}

.icon-line2-wallet:before {
  content: "\e02a";
}

.icon-line2-vector:before {
  content: "\e02b";
}

.icon-line2-speech:before {
  content: "\e02c";
}

.icon-line2-puzzle:before {
  content: "\e02d";
}

.icon-line2-printer:before {
  content: "\e02e";
}

.icon-line2-present:before {
  content: "\e02f";
}

.icon-line2-playlist:before {
  content: "\e030";
}

.icon-line2-pin:before {
  content: "\e031";
}

.icon-line2-picture:before {
  content: "\e032";
}

.icon-line2-map:before {
  content: "\e033";
}

.icon-line2-layers:before {
  content: "\e034";
}

.icon-line2-handbag:before {
  content: "\e035";
}

.icon-line2-globe-alt:before {
  content: "\e036";
}

.icon-line2-globe:before {
  content: "\e037";
}

.icon-line2-frame:before {
  content: "\e038";
}

.icon-line2-folder-alt:before {
  content: "\e039";
}

.icon-line2-film:before {
  content: "\e03a";
}

.icon-line2-feed:before {
  content: "\e03b";
}

.icon-line2-earphones-alt:before {
  content: "\e03c";
}

.icon-line2-earphones:before {
  content: "\e03d";
}

.icon-line2-drop:before {
  content: "\e03e";
}

.icon-line2-drawer:before {
  content: "\e03f";
}

.icon-line2-docs:before {
  content: "\e040";
}

.icon-line2-directions:before {
  content: "\e041";
}

.icon-line2-direction:before {
  content: "\e042";
}

.icon-line2-diamond:before {
  content: "\e043";
}

.icon-line2-cup:before {
  content: "\e044";
}

.icon-line2-compass:before {
  content: "\e045";
}

.icon-line2-call-out:before {
  content: "\e046";
}

.icon-line2-call-in:before {
  content: "\e047";
}

.icon-line2-call-end:before {
  content: "\e048";
}

.icon-line2-calculator:before {
  content: "\e049";
}

.icon-line2-bubbles:before {
  content: "\e04a";
}

.icon-line2-briefcase:before {
  content: "\e04b";
}

.icon-line2-book-open:before {
  content: "\e04c";
}

.icon-line2-basket-loaded:before {
  content: "\e04d";
}

.icon-line2-basket:before {
  content: "\e04e";
}

.icon-line2-bag:before {
  content: "\e04f";
}

.icon-line2-action-undo:before {
  content: "\e050";
}

.icon-line2-action-redo:before {
  content: "\e051";
}

.icon-line2-wrench:before {
  content: "\e052";
}

.icon-line2-umbrella:before {
  content: "\e053";
}

.icon-line2-trash:before {
  content: "\e054";
}

.icon-line2-tag:before {
  content: "\e055";
}

.icon-line2-support:before {
  content: "\e056";
}

.icon-line2-size-fullscreen:before {
  content: "\e057";
}

.icon-line2-size-actual:before {
  content: "\e058";
}

.icon-line2-shuffle:before {
  content: "\e059";
}

.icon-line2-share-alt:before {
  content: "\e05a";
}

.icon-line2-share:before {
  content: "\e05b";
}

.icon-line2-rocket:before {
  content: "\e05c";
}

.icon-line2-question:before {
  content: "\e05d";
}

.icon-line2-pie-chart:before {
  content: "\e05e";
}

.icon-line2-pencil:before {
  content: "\e05f";
}

.icon-line2-note:before {
  content: "\e060";
}

.icon-line2-music-tone-alt:before {
  content: "\e061";
}

.icon-line2-music-tone:before {
  content: "\e062";
}

.icon-line2-microphone:before {
  content: "\e063";
}

.icon-line2-loop:before {
  content: "\e064";
}

.icon-line2-logout:before {
  content: "\e065";
}

.icon-line2-login:before {
  content: "\e066";
}

.icon-line2-list:before {
  content: "\e067";
}

.icon-line2-like:before {
  content: "\e068";
}

.icon-line2-home:before {
  content: "\e069";
}

.icon-line2-grid:before {
  content: "\e06a";
}

.icon-line2-graph:before {
  content: "\e06b";
}

.icon-line2-equalizer:before {
  content: "\e06c";
}

.icon-line2-dislike:before {
  content: "\e06d";
}

.icon-line2-cursor:before {
  content: "\e06e";
}

.icon-line2-control-start:before {
  content: "\e06f";
}

.icon-line2-control-rewind:before {
  content: "\e070";
}

.icon-line2-control-play:before {
  content: "\e071";
}

.icon-line2-control-pause:before {
  content: "\e072";
}

.icon-line2-control-forward:before {
  content: "\e073";
}

.icon-line2-control-end:before {
  content: "\e074";
}

.icon-line2-calendar:before {
  content: "\e075";
}

.icon-line2-bulb:before {
  content: "\e076";
}

.icon-line2-bar-chart:before {
  content: "\e077";
}

.icon-line2-arrow-up:before {
  content: "\e078";
}

.icon-line2-arrow-right:before {
  content: "\e079";
}

.icon-line2-arrow-left:before {
  content: "\e07a";
}

.icon-line2-arrow-down:before {
  content: "\e07b";
}

.icon-line2-ban:before {
  content: "\e07c";
}

.icon-line2-bubble:before {
  content: "\e07d";
}

.icon-line2-camcorder:before {
  content: "\e07e";
}

.icon-line2-camera:before {
  content: "\e07f";
}

.icon-line2-check:before {
  content: "\e080";
}

.icon-line2-clock:before {
  content: "\e081";
}

.icon-line2-close:before {
  content: "\e082";
}

.icon-line2-cloud-download:before {
  content: "\e083";
}

.icon-line2-cloud-upload:before {
  content: "\e084";
}

.icon-line2-doc:before {
  content: "\e085";
}

.icon-line2-envelope:before {
  content: "\e086";
}

.icon-line2-eye:before {
  content: "\e087";
}

.icon-line2-flag:before {
  content: "\e088";
}

.icon-line2-folder:before {
  content: "\e089";
}

.icon-line2-heart:before {
  content: "\e08a";
}

.icon-line2-info:before {
  content: "\e08b";
}

.icon-line2-key:before {
  content: "\e08c";
}

.icon-line2-link:before {
  content: "\e08d";
}

.icon-line2-lock:before {
  content: "\e08e";
}

.icon-line2-lock-open:before {
  content: "\e08f";
}

.icon-line2-magnifier:before {
  content: "\e090";
}

.icon-line2-magnifier-add:before {
  content: "\e091";
}

.icon-line2-magnifier-remove:before {
  content: "\e092";
}

.icon-line2-paper-clip:before {
  content: "\e093";
}

.icon-line2-paper-plane:before {
  content: "\e094";
}

.icon-line2-plus:before {
  content: "\e095";
}

.icon-line2-pointer:before {
  content: "\e096";
}

.icon-line2-power:before {
  content: "\e097";
}

.icon-line2-refresh:before {
  content: "\e098";
}

.icon-line2-reload:before {
  content: "\e099";
}

.icon-line2-settings:before {
  content: "\e09a";
}

.icon-line2-star:before {
  content: "\e09b";
}

.icon-line2-symbol-female:before {
  content: "\e09c";
}

.icon-line2-symbol-male:before {
  content: "\e09d";
}

.icon-line2-target:before {
  content: "\e09e";
}

.icon-line2-volume-1:before {
  content: "\e09f";
}

.icon-line2-volume-2:before {
  content: "\e0a0";
}

.icon-line2-volume-off:before {
  content: "\e0a1";
}

.icon-line2-users:before {
  content: "\e001";
}

.icon-deezer:before {
  content: "\ee40";
}

.icon-edge-legacy:before {
  content: "\ee41";
}

.icon-google-pay:before {
  content: "\ee42";
}

.icon-google-plus:before {
  content: "\ee43";
}

.icon-rust:before {
  content: "\ee44";
}

.icon-tiktok:before {
  content: "\ee45";
}

.icon-tripadvisor:before {
  content: "\ee46";
}

.icon-unsplash:before {
  content: "\ee47";
}

.icon-yahoo:before {
  content: "\ee48";
}

.icon-box-tissue:before {
  content: "\ee49";
}

.icon-hand-holding-medical:before {
  content: "\ee4a";
}

.icon-hand-holding-water:before {
  content: "\ee4b";
}

.icon-hand-sparkles:before {
  content: "\ee4c";
}

.icon-hands-wash:before {
  content: "\ee4d";
}

.icon-handshake-alt-slash:before {
  content: "\ee4e";
}

.icon-handshake-slash:before {
  content: "\ee4f";
}

.icon-head-side-cough-slash:before {
  content: "\ee50";
}

.icon-head-side-cough:before {
  content: "\ee51";
}

.icon-head-side-mask:before {
  content: "\ee52";
}

.icon-head-side-virus:before {
  content: "\ee53";
}

.icon-house-user:before {
  content: "\ee54";
}

.icon-laptop-house:before {
  content: "\ee55";
}

.icon-lungs-virus:before {
  content: "\ee56";
}

.icon-people-arrows:before {
  content: "\ee57";
}

.icon-plane-slash:before {
  content: "\ee58";
}

.icon-pump-medical:before {
  content: "\ee59";
}

.icon-pump-soap:before {
  content: "\ee5a";
}

.icon-shield-virus:before {
  content: "\ee5b";
}

.icon-sink:before {
  content: "\ee5c";
}

.icon-socks:before {
  content: "\ee5d";
}

.icon-stopwatch-20:before {
  content: "\ee5e";
}

.icon-store-alt-slash:before {
  content: "\ee5f";
}

.icon-store-slash:before {
  content: "\ee60";
}

.icon-toilet-paper-slash:before {
  content: "\ee61";
}

.icon-users-slash:before {
  content: "\ee62";
}

.icon-virus-slash:before {
  content: "\ee63";
}

.icon-virus:before {
  content: "\ee64";
}

.icon-viruses:before {
  content: "\ee65";
}

.icon-bandcamp:before {
  content: "\ee66";
}

.icon-bacteria:before {
  content: "\ee67";
}

.icon-bacterium:before {
  content: "\ee68";
}

@font-face {
  font-family: "Koblenz";
  src: url("css/css/fonts/koblenz-book-webfont.eot");
  src: url("../fonts/koblenz-book-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/koblenz-book-webfont.woff") format("woff"), url("../fonts/koblenz-book-webfont.ttf") format("truetype"), url("../fonts/koblenz-book-webfont.svg#KoblenzRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "KoblenzMedium";
  src: url("../fonts/koblenz-medium-webfont.eot");
  src: url("../fonts/koblenz-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/koblenz-medium-webfont.woff") format("woff"), url("../fonts/koblenz-medium-webfont.ttf") format("truetype"), url("../fonts/koblenz-medium-webfont.svg#KoblenzMedium") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "KoblenzDemi";
  src: url("../fonts/koblenz-demi-webfont.eot");
  src: url("../fonts/koblenz-demi-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/koblenz-demi-webfont.woff") format("woff"), url("../fonts/koblenz-demi-webfont.ttf") format("truetype"), url("../fonts/koblenz-demi-webfont.svg#KoblenzDemi") format("svg");
  font-weight: normal;
  font-style: normal;
}
/*
@font-face {
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/fa-regular-400.eot'); // IE9 Compat Modes 
    src: local(''),
         url('../fonts/fa-regular-400.eot?#iefix') format('embedded-opentype'), // IE6-IE8 
         url('../fonts/fa-regular-400.woff2') format('woff2'), // Super Modern Browsers 
         url('../fonts/fa-regular-400.woff') format('woff'), // Modern Browsers 
         url('../fonts/fa-regular-400.ttf') format('truetype'), // Safari, Android, iOS 
         url('../fonts/fa-regular-400.svg#Fontawesome') format('svg'); // Legacy iOS 
}
*/
.klaro .cookie-modal .cm-modal.cm-klaro {
  background-color: #ffffff !important;
}

.klaro .cookie-notice:not(.cookie-modal-notice) {
  background-color: #1ABC9C !important;
}

.klaro .cm-header p {
  color: #333333 !important;
}

.klaro .context-notice a, .klaro .cookie-modal a, .klaro .cookie-notice a {
  color: black !important;
}

.embed-responsive-16by9::before {
  background-image: url("/img/sperre.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/*
.pfiseTopBlock .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
*/
.standard-logo2 img {
  object-fit: cover;
  max-height: 60px;
}

.menu-logo img {
  object-fit: cover;
  max-height: 60px;
}

.menu-item {
  margin-right: 20px;
  border-top: none !important;
}

.menu-link {
  font-family: "Koblenz", "Verdana", Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.pfiseTopNavi,
.pfiseTopNavi2 {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: slide-down 1s;
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 0.7;
    transform: translateY(0);
  }
}

.pfiseTopNavi2 ul {
  list-style-type: none;
  margin-bottom: 0;
}
.pfiseTopNavi2 ul .menu-item.active > .menu-link, .pfiseTopNavi2 ul .menu-item:hover > .menu-link {
  color: hsl(358deg, 66%, 44%);
}

.secondary-menu ul {
  list-style-type: none;
}
.secondary-menu ul .menu-link {
  padding: 10px;
  font-size: 20px !important;
}
.secondary-menu ul li.active > .menu-link {
  color: hsl(358deg, 66%, 44%);
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
  --bs-offcanvas-width: 400px;
}

a.languageTrigger {
  color: black;
}

.menuTrigger:hover, a.languageTrigger:hover {
  color: hsl(358deg, 66%, 44%);
}

.container-fluid.top-info, .top-info.container-sm, .top-info.container-md, .top-info.container-lg, .top-info.container-xl, .top-info.container-xxl {
  margin-top: 61px;
}

@media screen and (min-width: 767px) {
  .container-fluid.top-info, .top-info.container-sm, .top-info.container-md, .top-info.container-lg, .top-info.container-xl, .top-info.container-xxl {
    margin-top: 0px;
  }
  .standard-logo2 img {
    max-height: 100px;
  }
  .pfiseTopNavi {
    position: relative;
    font-size: 20px;
  }
  .menuTrigger {
    font-size: 20px;
  }
}
.hero {
  padding-top: 60px !important;
}
.hero img {
  min-width: 100%;
  min-height: 100%;
}

@media screen and (min-width: 767px) {
  .hero {
    padding-top: 0px !important;
  }
}
/*
.hero {
    //outline: 2px solid black;
    padding-top: 10em;
    padding-bottom: 3em;
    padding-left: 5em;
    padding-right: 5em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &.home {
        background-image: url('/images/header/Frontseite-ARU-Kettenbild.jpg');
        //background-image: url('/images/header/header-ketten-transparent2.png');
    }
    &.ohrschmuck {
        background-image: url('/images/header/Startseite-ohne-Schrift-fertig.jpg');
    }
    &.ketten {
        //background-image: url('/images/header/header-neue-Kette.jpg');
        background-image: url('/images/header/header-ketten-omega.jpg');
    }
    &.unternehmen {
        background-image: url('/images/diverse/armbrust-ruf-gebaeude.jpg');
    }
    &.b2bImg {
        background-image: url('/images/header/header_shop2.jpg');
    }
    &.kollektion {
        background-image: url('/images/header/kollektionsentwicklung.jpg');
    }
    &.kontakt {
        //background-image: url('/images/diverse/bild2.jpg');
        background-image: url('/images/header/Kettenglieder.jpg');
    }
}

.heroText {
    //background-color: hsl(358, 66%, 44%);
    background-color: #caad61; // hsla(43, 50%, 59%, 0.784); //  	
    opacity: 0.9;
    color: white;
    padding: 10px;
    text-shadow: 1px 1px #777777;
    border-image: linear-gradient( to right, white, #caac613c, white) 1;
    border-width: 4px;
    border-style: solid;
    border-left: none;
    border-right: none;
}

@include media-breakpoint-up(sm) {
//@media screen and (min-width: 576px) {
    .hero {
        padding-top: 15em;
        padding-bottom: 5em;
        padding-left: 10em;
        padding-right: 10em;
    }
}

@include media-breakpoint-up(md) {
//@media screen and (min-width: 767px) {
    .hero {
        padding-top: 20em;
        padding-bottom: 5em;
        padding-left: 15em;
        padding-right: 15em;
    }
}

@include media-breakpoint-up(lg) {
//@media screen and (min-width: 992px) {
    .hero {
        padding-top: 25em;
        padding-bottom: 10em;
        padding-left: 20em;
        padding-right: 20em;
    }
}    

@include media-breakpoint-up(xl) {
//@media screen and (min-width: 1200px) {
    .hero {
        padding-top: 25em;
        padding-bottom: 10em;
        padding-left: 30em;
        padding-right: 30em;
    }
}    

@include media-breakpoint-up(xxl) {
//@media screen and (min-width: 1400px) {
    }    
*/
.ohrschmuckSlider .row {
  max-width: 50%;
}
.ohrschmuckSlider .carousel {
  width: 400px;
}

.carousel-item div {
  font-size: 32px;
  background: rgb(235, 209, 139);
  background: linear-gradient(163deg, rgb(235, 209, 139) 0%, rgb(255, 255, 255) 45%, rgb(222, 226, 230) 100%);
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  margin-bottom: 0 !important;
  text-align: center;
}
.carousel-item img {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #caad61;
  width: 50px;
  height: 50px;
}

.meinElement1, .meinElement2 {
  opacity: 0;
  transition: all 2.25s ease-in-out;
}

.meinElement1 {
  transform: translateX(-90%);
}

.meinElement2 {
  transform: translateX(90%);
}

.meinElement1.visible {
  transform: translateX(0);
  opacity: 1;
}

.meinElement2.visible {
  transform: translateX(0);
  opacity: 1;
}

.meinElement3, .meinElement4 {
  opacity: 0;
  transition: all 2.25s ease-in-out;
}

.meinElement3 {
  transform: translateX(-90%);
}

.meinElement4 {
  transform: translateX(90%);
}

.meinElement3.visible {
  transform: translateX(0);
  opacity: 1;
}

.meinElement4.visible {
  transform: translateX(0);
  opacity: 1;
}

/*
@keyframes wipe-enter {
  0% {
    transform: scale(0, 0.025);
  }
  50% {
    transform: scale(1, 0.025);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .square {
    animation-name: wipe-enter;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

.square {
  width: 200px;
  height: 200px;
  background: orange;
  // etc...
}

@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    animation: wipe-enter 1s 1;
  }
}
*/
body {
  font-family: "Koblenz", "Verdana", Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Koblenz", "Verdana", Arial, Helvetica, sans-serif;
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  padding: 0 !important;
}

.content-wrap {
  padding: 0;
}

#content {
  background-color: #F8F9FA !important;
}

#footer {
  border-top: none;
  background-color: #ffffff !important;
}

#copyrights {
  background-color: #ffffff !important;
  font-size: 18px;
}

.bg-color-p {
  background-color: hsl(358deg, 66%, 44%);
}

.bg-color-p2 {
  background-color: #AEBDDC;
}

.bg-color-p3 {
  background-color: #f8f8f8;
}

.container, .container-lg, .container-md, .container-sm, .container-xl {
  max-width: 1450px;
}

.logo-slogan {
  display: none;
}

@media (min-width: 992px) {
  .logo-slogan {
    display: inline-block;
    color: #000000;
  }
}
#primary-menu-trigger {
  display: flex;
}

.footer {
  margin-top: 20px;
  background-color: #f8f8f8;
}

.footer-info {
  background-color: white;
  color: #000000;
}

.footer-text {
  font-family: "Varela Round", sans-serif;
  background-color: #f8f8f8;
  color: hsl(358deg, 66%, 44%);
}
.footer-text a {
  color: hsl(358deg, 66%, 44%);
}

.slogan {
  background: rgb(255, 255, 255);
  background: linear-gradient(198deg, rgb(255, 255, 255) 0%, rgb(219, 200, 148) 49%, rgb(222, 226, 230) 100%);
  padding: 3em;
  text-shadow: 1px 1px white;
  /*
  background-image: url('/images/diverse/armbrust-ruf-produktion.jpg');
  background-size: cover;
  background-position: center;
  */
  border: none;
}
.slogan .sloganText {
  padding-top: 3em;
  padding-bottom: 3em;
}
.slogan .sloganTextohneHero {
  padding-top: 4em;
  padding-bottom: 0em;
}

.fadeInDiv {
  animation: fadeIn 5s;
}

.box1 {
  border-image: linear-gradient(to right, #dee2e6, #caad61, #dee2e6) 1;
  border-width: 4px;
  border-style: solid;
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding: 10px;
  padding: 20px;
}
.box1 h2, .box1 .h2, .box1 a {
  color: #000000 !important;
}

.box2 {
  background-color: #f3f4f8;
  border-image: linear-gradient(to right, #f3f4f8, #caad61) 1;
  border-width: 4px;
  border-style: solid;
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding: 10px;
  padding: 20px;
}
.box2 h2, .box2 .h2, .box2 a {
  color: #000000 !important;
}

.bRight {
  margin-left: 10px;
  background-color: #ffffff;
}

.bCenter {
  margin-right: 10px;
  background-color: #ffffff;
}

.bLeft {
  margin-right: 10px;
  background-color: #ffffff;
}

.pfBorder {
  border-image: linear-gradient(to right, #dee2e6, #caad61, #dee2e6) 1;
  border-width: 4px;
  border-style: solid;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.categories {
  font-size: 24px;
  padding-top: 30px;
}

.categoriesDummy {
  padding-bottom: 10px;
}

.service {
  font-size: 24px;
  background: rgb(235, 209, 139);
  background: linear-gradient(163deg, rgb(235, 209, 139) 0%, rgb(255, 255, 255) 45%, rgb(222, 226, 230) 100%);
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
  margin-bottom: 0 !important;
}

.firma {
  font-size: 24px;
  background: rgb(222, 226, 230);
  background: linear-gradient(22deg, rgb(222, 226, 230) 0%, rgb(255, 255, 255) 100%, rgba(240, 224, 184, 0.9859653334) 100%);
}
.firma a:link {
  color: black !important;
}

.katalog {
  font-size: 24px;
  padding-top: 3em;
  padding-bottom: 3em;
  background: rgb(222, 226, 230);
  background: linear-gradient(70deg, rgb(222, 226, 230) 0%, rgb(255, 255, 255) 49%, rgba(240, 224, 184, 0.9859653334) 90%);
}

.b2b {
  font-size: 24px;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  margin-bottom: 0 !important;
}

.b2bShop {
  font-size: 24px;
  background-color: #E8E8E8;
  padding-top: 1em !important;
  padding-bottom: 3em !important;
}

.kettenInfo {
  font-size: 24px;
  background: rgb(235, 209, 139);
  background: linear-gradient(163deg, rgb(235, 209, 139) 0%, rgb(255, 255, 255) 45%, rgb(222, 226, 230) 100%);
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
  margin-bottom: 0 !important;
}

.kontakt {
  font-size: 20px;
}

.backToTop {
  position: fixed;
  bottom: 10px;
  left: 25px;
  display: none;
  padding: 10px;
  background-color: hsl(358deg, 66%, 44%);
  color: white;
  border-radius: 5px;
}
.backToTop:hover {
  background-color: #f8f8f8;
  color: black;
}

.container-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 50%;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.icon-ar {
  background-color: white;
  color: hsl(358deg, 66%, 44%);
}

@media screen and (min-width: 767px) {
  .slogan .sloganTextohneHero {
    padding-top: 3em;
    padding-bottom: 3em;
  }
}