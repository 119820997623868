// Eigene Definitionen für das Projekt

// Standarddefinitionen von Boosttrap müssen vor Bootstrap geladen werden
@import "./change_bootstrap_variables";

// Option A: Include all of Bootstrap
//@import "../../node_modules/bootstrap/scss/bootstrap";
//@import "../vendor/bootstrap/bootstrap-4.5.3-dist/scss/bootstrap.scss";
//@import "../../node_modules/bootstrap/scss/bootstrap.scss";
//@import "../vendor/bootstrap-5.2.0-beta1/dist/css/bootstrap.min.css";
@import "../vendor/bootstrap-5.2.0-beta1/scss/bootstrap.scss";
//@import "../vendor/canvas/canvas-style.scss";
@import "../vendor/canvas/canvas-style.css";

/*
// Option B: Include parts of Bootstrap
// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Optional
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
*/

// Standarddefinitionen 
@import "./variables";

// Module includieren
@import "./font-icons";
@import "./fonts";
@import "./klaro";
@import "./sticky-top";
@import "./hero";
@import "./carousel";
@import "./animations";
@import "./styles";
