//@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

// Siehe: https://google-webfonts-helper.herokuapp.com/fonts/varela-round?subsets=latin

@font-face {
    font-family: 'Koblenz';
    src: url('css/css/fonts/koblenz-book-webfont.eot');
    src:url('../fonts/koblenz-book-webfont.eot?#iefix') format('embedded-opentype'),
   url('../fonts/koblenz-book-webfont.woff') format('woff'),
   url('../fonts/koblenz-book-webfont.ttf') format('truetype'),
   url('../fonts/koblenz-book-webfont.svg#KoblenzRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'KoblenzMedium';
    src:url('../fonts/koblenz-medium-webfont.eot');
    src:url('../fonts/koblenz-medium-webfont.eot?#iefix') format('embedded-opentype'),
   url('../fonts/koblenz-medium-webfont.woff') format('woff'),
   url('../fonts/koblenz-medium-webfont.ttf') format('truetype'),
   url('../fonts/koblenz-medium-webfont.svg#KoblenzMedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'KoblenzDemi';
    src:url('../fonts/koblenz-demi-webfont.eot');
    src:url('../fonts/koblenz-demi-webfont.eot?#iefix') format('embedded-opentype'),
   url('../fonts/koblenz-demi-webfont.woff') format('woff'),
   url('../fonts/koblenz-demi-webfont.ttf') format('truetype'),
   url('../fonts/koblenz-demi-webfont.svg#KoblenzDemi') format('svg');
    font-weight: normal;
    font-style: normal;
}

/*
@font-face {
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/fa-regular-400.eot'); // IE9 Compat Modes 
    src: local(''),
         url('../fonts/fa-regular-400.eot?#iefix') format('embedded-opentype'), // IE6-IE8 
         url('../fonts/fa-regular-400.woff2') format('woff2'), // Super Modern Browsers 
         url('../fonts/fa-regular-400.woff') format('woff'), // Modern Browsers 
         url('../fonts/fa-regular-400.ttf') format('truetype'), // Safari, Android, iOS 
         url('../fonts/fa-regular-400.svg#Fontawesome') format('svg'); // Legacy iOS 
}
*/
