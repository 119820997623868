/*
.pfiseTopBlock .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
*/

.standard-logo2 {
  img {
    object-fit: cover;
    max-height: 60px;
  }
}

.menu-logo {
  img {
    object-fit: cover;
    max-height: 60px;
  }
}

.menu-item {
  margin-right: 20px;
  border-top: none !important;
}

.menu-link {
    font-family:'Koblenz','Verdana', Arial, Helvetica, sans-serif;
    font-size: 16px;
}

.pfiseTopNavi,
.pfiseTopNavi2 {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  box-shadow: 0 0 10px rgba($black,0.1);

  animation: slide-down 1s;
  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 0.7;
      transform: translateY(0);
    }
  }
}

.pfiseTopNavi2 ul {
  list-style-type: none;
  margin-bottom: 0;

  .menu-item.active > .menu-link, .menu-item:hover > .menu-link {
    color: $primary;
  }
}

.secondary-menu ul {
  list-style-type: none;

  .menu-link {
    padding: 10px;
    font-size: 20px !important;
  }

  li.active  > .menu-link {
    color: $primary;
  }
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
  --bs-offcanvas-width: 400px;
}

a.languageTrigger {
  color: black;
}

.menuTrigger:hover, a.languageTrigger:hover {
  color: $primary;
  //text-decoration: underline;
}

.container-fluid.top-info {
  margin-top: 61px;
}

//@include media-breakpoint-up(md) {
@media screen and (min-width: 767px) {
  .container-fluid.top-info {
    margin-top: 0px;
  }
  .standard-logo2 {
    img {
      max-height: 100px;
    }
  }
  .pfiseTopNavi {
    position: relative;
    font-size: 20px;
  }
  .menuTrigger {
    font-size: 20px;
  }
}
