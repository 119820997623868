.ohrschmuckSlider {
    .row {
        max-width: 50%;
    }
    .carousel {
        width: 400px;
    }
}

.carousel-item {

    div {
        font-size: 32px;
        background: rgb(235,209,139);
        background: linear-gradient(163deg, rgba(235,209,139,1) 0%, rgba(255,255,255,1) 45%, rgba(222,226,230,1) 100%);
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        margin-bottom: 0 !important;
        text-align: center;
    }

    img {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: white;
    }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: #caad61;
    width: 50px;
    height: 50px;
}