// Layout KLaro anpassen

.klaro .cookie-modal .cm-modal.cm-klaro {
    background-color: #ffffff !important;
}

.klaro .cookie-notice:not(.cookie-modal-notice) {
    background-color: #1ABC9C !important;
}

.klaro .cm-header p {
    color: #333333 !important;
}

.klaro .context-notice a, .klaro .cookie-modal a, .klaro .cookie-notice a {
    color: black !important;
}

.embed-responsive-16by9::before {
    background-image: url("/img/sperre.gif");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
