.hero {

    img {
        min-width: 100%;
        min-height: 100%
    }
    padding-top: 60px !important;
}

@media screen and (min-width: 767px) {
    .hero {
        padding-top: 0px !important;
    }
}


/*
.hero {
    //outline: 2px solid black;
    padding-top: 10em;
    padding-bottom: 3em;
    padding-left: 5em;
    padding-right: 5em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &.home {
        background-image: url('/images/header/Frontseite-ARU-Kettenbild.jpg');
        //background-image: url('/images/header/header-ketten-transparent2.png');
    }
    &.ohrschmuck {
        background-image: url('/images/header/Startseite-ohne-Schrift-fertig.jpg');
    }
    &.ketten {
        //background-image: url('/images/header/header-neue-Kette.jpg');
        background-image: url('/images/header/header-ketten-omega.jpg');
    }
    &.unternehmen {
        background-image: url('/images/diverse/armbrust-ruf-gebaeude.jpg');
    }
    &.b2bImg {
        background-image: url('/images/header/header_shop2.jpg');
    }
    &.kollektion {
        background-image: url('/images/header/kollektionsentwicklung.jpg');
    }
    &.kontakt {
        //background-image: url('/images/diverse/bild2.jpg');
        background-image: url('/images/header/Kettenglieder.jpg');
    }
}

.heroText {
    //background-color: hsl(358, 66%, 44%);
    background-color: #caad61; // hsla(43, 50%, 59%, 0.784); //  	
    opacity: 0.9;
    color: white;
    padding: 10px;
    text-shadow: 1px 1px #777777;
    border-image: linear-gradient( to right, white, #caac613c, white) 1;
    border-width: 4px;
    border-style: solid;
    border-left: none;
    border-right: none;
}

@include media-breakpoint-up(sm) {
//@media screen and (min-width: 576px) {
    .hero {
        padding-top: 15em;
        padding-bottom: 5em;
        padding-left: 10em;
        padding-right: 10em;
    }
}

@include media-breakpoint-up(md) {
//@media screen and (min-width: 767px) {
    .hero {
        padding-top: 20em;
        padding-bottom: 5em;
        padding-left: 15em;
        padding-right: 15em;
    }
}

@include media-breakpoint-up(lg) {
//@media screen and (min-width: 992px) {
    .hero {
        padding-top: 25em;
        padding-bottom: 10em;
        padding-left: 20em;
        padding-right: 20em;
    }
}    

@include media-breakpoint-up(xl) {
//@media screen and (min-width: 1200px) {
    .hero {
        padding-top: 25em;
        padding-bottom: 10em;
        padding-left: 30em;
        padding-right: 30em;
    }
}    

@include media-breakpoint-up(xxl) {
//@media screen and (min-width: 1400px) {
    }    
*/